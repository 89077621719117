import { get, post } from "./request"

interface IApiGroup {
    name: string
    count: number
    startIndex: number
}

interface IApiItem {
    id: string
    name: string
    type: number
    typeName: string
    groupId: string
    group: string
}

interface IApiDataList {
    groups: IApiGroup[]
    items: IApiItem[]
}

interface IApiQueryItem {
    x: string,
    y: string,
    cellType: string,
    geneData: string
}

interface IApiRowQueryItem {
    name: string,
    geneData: string[]
}

interface IApiLoadRegenomicsData {
    base: string,
    urls: string[],
    map: Record<string, string>
}

const createGroup = async (name: string) => (await post('/api/group/create', { name })).data
const deleteGroup = async (name: string) => (await post('/api/group/delete', { name })).data
const deleteData = async (group: string, name: string) => (await post('/api/cell/delete', { group, name })).data
const moveDataPos = async (group: string, name: string, pos: number) => (await post('/api/cell/movePos', { group, name, pos })).data
const getDataList = async () => (await get<IApiDataList>('/api/cell/loadList')).data
const queryData = async (items: IApiItem[], gene: string) => (await post<IApiQueryItem[]>('/api/cell/query', { items: JSON.stringify(items.map(e => ({ groupId: e.groupId, id: e.id, type: e.type }))), gene })).data
const queryRowData = async (items: IApiItem[], genes: string[]) => (await post<IApiRowQueryItem[]>('/api/cell/queryRow', { items: JSON.stringify(items.map(e => ({ groupId: e.groupId, id: e.id, type: e.type }))), genes: JSON.stringify(genes) })).data
const loadRegenomicsData = async (password: string) => (await post<IApiLoadRegenomicsData>('/api/public/loadRegenomicsData', { password })).data

export { createGroup, deleteGroup, getDataList, deleteData, moveDataPos, queryData, queryRowData,loadRegenomicsData }

export type { IApiItem, IApiGroup, IApiQueryItem,IApiLoadRegenomicsData }