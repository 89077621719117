import { DefaultButton, Dialog, DialogFooter, PrimaryButton, TextField } from '@fluentui/react';
import React, { useEffect, useState } from 'react';

interface IInputDialog {
    title: string
    subText?: string
    label?: string
    hideDialog: boolean
    errorMessage?: string
    onValueChange?: (str: string) => void
    toggleHideDialog: () => void
    toggleConfirm: (str: string) => void
}

const InputDialog: React.FC<IInputDialog> = (props) => {
    const [value, setValue] = useState('')

    useEffect(() => {
        props.hideDialog && setValue('')
    }, [props.hideDialog])

    return (
        <>
            <Dialog
                hidden={props.hideDialog}
                onDismiss={props.toggleHideDialog}
                dialogContentProps={{
                    title: props.title,
                    subText: props.subText,
                }}
            >
                <TextField
                    value={value}
                    onChange={(_, newValue) => {
                        setValue(newValue ?? '')
                        props.onValueChange?.(newValue ?? '')
                    }}
                    label={props.label}
                    errorMessage={props.errorMessage}
                />
                <DialogFooter>
                    <PrimaryButton onClick={() => props.toggleConfirm(value)} text="确定" />
                    <DefaultButton onClick={props.toggleHideDialog} text="取消" />
                </DialogFooter>
            </Dialog>
        </>
    );
}

export default InputDialog