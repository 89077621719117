import * as echarts from 'echarts';

const optionToSvg = (option: echarts.EChartsCoreOption, theme: string, width = 1920, height = 1080) => {
    const instance = echarts.init(undefined as unknown as HTMLElement, theme, {
        renderer: 'svg',
        ssr: true,
        width,
        height,
        locale: 'EN'
    });
    const newOption: any = { ...option, toolbox: undefined, animation: false }
    for (let i = 0; i < newOption.series.length; i++)
        newOption.series[i] = { ...newOption.series[i], progressive: 0 }
    instance.setOption(newOption)
    return instance.renderToSVGString()
}

const downloadTxt = (fileName: string, content: string) => {
    const blob = new Blob([content], {
        type: "text/plain;charset=utf-8"
    });
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = function (e) {
        const a = document.createElement('a');
        a.download = fileName;
        a.href = e.target?.result as string;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
}

export { optionToSvg, downloadTxt }