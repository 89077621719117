import { Grid, Typography } from '@mui/material';
import ItemCard from './ItemCard';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { useAppSelector } from 'src/service/store';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const CategorySelection: React.FC = () => {
    const { groups } = useAppSelector(e => e.dataset);
    const params = useParams();

    const categorys = useMemo(() => {
        const start = params.series + '/';
        return Array.from(new Set(groups.filter(e => e.name.startsWith(start)).map(e => e.name.slice(start.length).split('/')[0])))
    }, [groups, params.series])

    const config = useAppSelector(e => e.config);

    return <div>
        <Grid container spacing={4}>
            {categorys.map(e => (
                <Grid key={e} item>
                    <ItemCard name={e} series={params.series ?? ''} />
                </Grid>
            ))}
            {params.series == 'PRSTA' && <>
                <div style={{ paddingTop: 48, paddingBottom: 48 }}>
                    <Typography variant="h6">
                        PRSTA: Planarian Regeneration Spatiotemporal Transcriptomic Atlas
                    </Typography>
                    The launch version of the PRSTA database contains a total of 3 categories of data, including wild-type whole-body regeneration atlas, nsf RNAi and scg5 RNAi data. For the wild-type category, there are time-series bulk RNA-seq data, single-cell RNA-seq data, and two sets of cellular-level spatial transcriptomics data from 10X Visium (mapped by DRIM) and BGI Stereo-seq, which covers nearly a complete process of planarian whole- body regeneration. As for RNAi categories, single-cell RNA-seq data and spatial RNA-seq data of both control(RNAi) and nsf/scg5(RNAi) were presented. With the PRSTA, we provide flexible online tools for searching the gene expression and cluster distribution in space and time. Our panoramic atlas allows in-depth investigation of longstanding questions concerning whole-body regeneration.
                </div>
                <div style={{ width: '100%' }}>
                    <Carousel>
                        <div>
                            {config.theme == 'light' && <img
                                style={{ width: '100%' }}
                                src="/images/sc_1_light.svg" />}
                            {config.theme != 'light' && <img
                                style={{ width: '100%' }}
                                src="/images/sc_1_dark.svg" />}
                        </div>
                        <div>
                            {config.theme == 'light' && <img
                                style={{ width: '80%', margin: '0 10% 0 10%' }}
                                src="/images/sp_1_light.svg" />}
                            {config.theme != 'light' && <img
                                style={{ width: '80%', margin: '0 10% 0 10%' }}
                                src="/images/sp_1_dark.svg" />}
                        </div>
                    </Carousel>
                </div>
            </>}
        </Grid>
    </div>
};

export default CategorySelection