import { ComboBox, DefaultButton, Dialog, DialogFooter, IComboBoxOption, IGroup, Label, PrimaryButton, ProgressIndicator, Stack, TextField } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { formatSize, selectFile } from 'src/service/file';
import EventEmitter from 'events'
import { post, upload } from 'src/service/request';
import { gzip } from 'src/service/gzip';

interface IUploadDialog {
    hideDialog: boolean
    groups: IGroup[]
    selectedGroup?: string
    onSelectGroup?: (group: string) => void
    onUploaded?: () => void
    toggleHideDialog: () => void
}

interface UploadChunk {
    range: number[],
    hash?: string
}

interface IUploadLog {
    loaded: number
    time: number
}

const chunkSize = 32 * 1024 * 1024;
const ee = new EventEmitter()

const getSlice = (file: File, range: number[]) => (
    file.slice(range[0], range[1])
)

const ConfirmDialog: React.FC<IUploadDialog> = (props) => {
    const options: IComboBoxOption[] = useMemo(() => props.groups.map(e => ({ key: e.key, text: e.name })), [props.groups]);
    const [file, setFile] = useState<File>();
    const [uploading, uploadingCallback] = useBoolean(false)
    const stop = useRef(true)
    const uploadLog = useRef<IUploadLog[]>([]);
    const [uploadProgress, setUploadProgress] = useState(0)
    const [name, setName] = useState<string>()
    const [groupErrorMessage, setGroupErrorMessage] = useState<string>()
    const [nameErrorMessage, setNameErrorMessage] = useState<string>()
    const [fileErrorMessage, setFileErrorMessage] = useState<string>()

    const toggleBowserFile = useCallback(async () => {
        setFile(await selectFile())
    }, [])

    const uploadChunk = useCallback(async (file: File, chunk: UploadChunk): Promise<void> => {
        const blob = getSlice(file, chunk.range);
        const uploaded = [0];
        const updateProgress = (loaded: number) => {
            setUploadProgress(old => {
                const tmp = old + loaded - uploaded[0];
                const time = new Date().getTime();
                if (uploadLog.current.length < 2 || time - uploadLog.current[uploadLog.current.length - 1].time >= 1000) {
                    uploadLog.current.push({ loaded: tmp, time: new Date().getTime() })
                    uploadLog.current = uploadLog.current.filter(e => e.time > time - 5000)
                }
                return tmp;
            })
            uploaded[0] = loaded;
        }
        const compressed = await gzip(blob);
        const ratio = compressed.size / blob.size;
        const ret = (await upload<string>(`/api/cell/upload`, compressed, e => updateProgress(e.loaded / ratio))).data;
        if (ret.code != 0) throw new Error(ret.message);
        chunk.hash = ret.data;
        updateProgress(chunk.range[1] - chunk.range[0]);
    }, [])

    const startUpload = useCallback(() => {
        let err = false;
        if (file == undefined) {
            setFileErrorMessage('请选择文件')
            err = true
        }
        if (name == undefined || name == '') {
            setNameErrorMessage('名称不能为空')
            err = true
        }
        if (props.groups.every(e => e.key != props.selectedGroup)) {
            setGroupErrorMessage('分组不存在')
            err = true
        }
        if (file == undefined || err) {
            return
        }
        uploadingCallback.setTrue()
        setUploadProgress(0)
        uploadLog.current = []
        stop.current = false
        const group = props.selectedGroup;
        const chunks: UploadChunk[] = [];
        for (let j = 0; j < file.size; j += chunkSize)
            chunks.push({ range: [j, Math.min(j + chunkSize, file.size)] });
        const cur_chunk = [0];
        ee.addListener('next', async () => {
            if (stop.current) {
                ee.removeAllListeners('next');
                return
            }
            if (chunks.every(e => e.hash)) {
                ee.removeAllListeners('next');
                post('/api/cell/add', {
                    hashArr: JSON.stringify(chunks.map(e => e.hash)),
                    group,
                    name
                }).then(
                    ({ data }) => {
                        if (data.code == 0) {
                            props.onUploaded?.()
                            uploadingCallback.setFalse()
                            props.onUploaded?.()
                        } else {
                            uploadingCallback.setFalse()
                            if (data.message.indexOf('分组') > -1) {
                                setGroupErrorMessage(data.message)
                            } else if (data.message.indexOf('名称') > -1) {
                                setNameErrorMessage(data.message)
                            } else {
                                setFileErrorMessage(data.message)
                            }
                        }
                    },
                    (e) => {
                        uploadingCallback.setFalse()
                        setFileErrorMessage(e.message)
                    }
                )
                return;
            }
            if (cur_chunk[0] >= chunks.length) {
                return
            }
            const tmp_chunk = cur_chunk[0]++;
            uploadChunk(file, chunks[tmp_chunk]).then(
                () => {
                    ee.emit('next');
                }, (e) => {
                    ee.removeAllListeners('next');
                    uploadingCallback.setFalse()
                    setNameErrorMessage(e.message)
                }
            )
        });
        for (let i = 0; i < 8; i++) {
            ee.emit('next');
        }
    }, [file, name, props, uploadChunk, uploadingCallback])

    useEffect(() => {
        stop.current = !uploading
    }, [uploading])

    useEffect(() => {
        setFileErrorMessage(undefined)
    }, [file])

    useEffect(() => {
        setGroupErrorMessage(undefined)
    }, [props.selectedGroup])

    useEffect(() => {
        setNameErrorMessage(undefined)
    }, [name])

    const processing = file && uploadProgress >= file.size * 0.99;
    const log0 = uploadLog.current[0]
    const log1 = uploadLog.current[uploadLog.current.length - 1]
    const speed = uploadLog.current.length >= 2 && log1.time > log0.time ? formatSize((log1.loaded - log0.loaded) / (log1.time - log0.time) * 1000) + "/s" : "-"

    return (
        <>
            <Dialog
                hidden={props.hideDialog}
                onDismiss={props.toggleHideDialog}
                dialogContentProps={{
                    title: "文件上传"
                }}
            >
                {!uploading && <>
                    <ComboBox
                        errorMessage={groupErrorMessage}
                        selectedKey={props.selectedGroup}
                        onChange={(_, option) => option && props.onSelectGroup?.(option.key as string)}
                        label="上传到分组"
                        autoComplete="on"
                        options={options}
                    />
                    <TextField
                        errorMessage={nameErrorMessage}
                        label="名称"
                        value={name}
                        onChange={(_, newValue) => setName(newValue)}
                    />
                    <Label>文件</Label>
                    <Stack horizontal style={{ paddingBottom: 16 }}>
                        <TextField
                            errorMessage={fileErrorMessage}
                            readOnly
                            defaultValue="请选择文件"
                            value={file?.name}
                        />
                        <DefaultButton style={{ marginLeft: 8 }} onClick={toggleBowserFile}>浏览</DefaultButton>
                    </Stack>
                </>}
                {uploading && file && <>
                    <ProgressIndicator
                        label={processing ? "建立索引中..." : "正在上传中..."}
                        percentComplete={processing ? undefined : uploadProgress / file.size}
                        description={processing ? "大约需要1分钟" : speed}
                    />
                </>}
                <DialogFooter>
                    {!uploading && <PrimaryButton onClick={startUpload} text="开始上传" />}
                    <DefaultButton onClick={props.toggleHideDialog} text="关闭" />
                </DialogFooter>
            </Dialog>
        </>
    );
}

export default ConfirmDialog