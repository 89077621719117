import { Button, Paper, Stack, SxProps, TextField, Theme, Typography, Container, Link, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { loadUserInfo, signupUser } from "src/service/user";
import { handleValueChange } from "src/utility/common";

const SignupPaperStyle: SxProps<Theme> = {
    maxWidth: 350,
    margin: '0 auto',
    padding: 4,
    display: 'flex',
    flexDirection: 'column'
}

const SignupPage: React.FC = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [helperText, setHelperText] = useState({ username: '', email: '', password: '' });

    const [errmsg, setErrmsg] = useState('');
    const navigate = useNavigate();

    const handleSignup = async () => {
        const ret = await signupUser(username, email, password);
        if (ret.code == 0)
            navigate('/login');
        else if (ret.data)
            ret.data.forEach(x => setHelperText(old => ({ ...old, [x.field]: x.message })))
        else
            setErrmsg(ret.message)
    }

    useEffect(() => {
        setErrmsg('')
    }, [username, email, password])

    useEffect(() => {
        setHelperText(old => ({ ...old, username: '' }))
    }, [username])

    useEffect(() => {
        setHelperText(old => ({ ...old, email: '' }))
    }, [email])

    useEffect(() => {
        setHelperText(old => ({ ...old, password: '' }))
    }, [password])

    return (
        <Container sx={{ pt: 10 }}>
            <Paper elevation={3} sx={SignupPaperStyle}>
                <Stack spacing={4} sx={{ mb: 2 }}>
                    <Typography variant="h5" sx={{ textAlign: 'center' }}>
                        Signup
                    </Typography>
                    <TextField
                        error={!!helperText['username']}
                        helperText={helperText['username']}
                        label="Username"
                        value={username}
                        onChange={handleValueChange(setUsername)} />
                    <TextField
                        error={!!helperText['email']}
                        helperText={helperText['email']}
                        label="Email"
                        value={email}
                        type={'email'}
                        onChange={handleValueChange(setEmail)} />
                    <TextField
                        error={!!helperText['password']}
                        helperText={helperText['password']}
                        label="Password"
                        value={password}
                        type={'password'}
                        onChange={handleValueChange(setPassword)} />
                </Stack>
                {errmsg && <Alert severity="error">{errmsg}</Alert>}
                <Button variant="contained" onClick={handleSignup} sx={{ mt: 2 }}>Signup</Button>
                <Link textAlign={'center'} sx={{ pt: 2 }} href={'#/login'}>Back to Login</Link>
            </Paper>
        </Container>
    )
}

export default SignupPage;