import { Alert, Box, Checkbox, FormControlLabel, FormGroup, Grid, Slider, Stack, Typography } from "@mui/material";
import { IRowGeneData } from "src/container/Sidebars/SidebarHeatMap";
import SidebarHeatMap from "src/container/Sidebars/SidebarHeatMap";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactECharts from 'echarts-for-react';
import Scrollbars from "react-custom-scrollbars";
import styles from './index.module.scss'
import { useLocalStorage } from "src/service/hooks";
import { useAppSelector } from "src/service/store";
import { downloadTxt, optionToSvg } from "src/service/echarts";

const HeatMap2: React.FC = () => {
    const [errorMsg, setErrorMsg] = useState<string>();
    const [geneData, setGeneData] = useState<IRowGeneData[]>([]);
    const [columns, setColumns] = useLocalStorage('HeatMapColumns', 1);
    const [rows, setRows] = useLocalStorage('HeatMapRows', 1);
    const [heatMapDataNormalization, setHeatMapDataNormalization] = useLocalStorage('HeatMapDataNormalization', false);
    const [heatMapYAxisScale, setHeatMapYAxisScale] = useLocalStorage('HeatMapYAxisScale', false);
    const heatMapOptionRef = useRef<any>()
    const linerOptionRef = useRef<any>()

    useEffect(() => {
        if (geneData.length > 0) {
            setErrorMsg(undefined)
        }
    }, [geneData])

    useEffect(() => {
        if (errorMsg) {
            setGeneData([])
        }
    }, [errorMsg])

    const theme = useAppSelector(e => e.config.theme);

    const exportHeatMapSvg = useCallback(async (i: number) => {
        downloadTxt(linerOptionRef.current[i].title.text + '.svg', optionToSvg(heatMapOptionRef.current[i], theme, 400, 800));
    }, [theme])

    const exportLinerSvg = useCallback(async (i: number) => {
        downloadTxt(linerOptionRef.current[i].title.text + '.svg', optionToSvg(linerOptionRef.current[i], theme, 1000, 800));
    }, [theme])

    const optionLeft = useMemo(() => geneData.map((e, i) => {
        const values = e.geneData.reduce((pre, cur) => [...pre, ...cur], [])
        const maxArr = e.geneData.map(arr => Math.max(...arr))
        const minArr = e.geneData.map(arr => Math.min(...arr))
        const normalization = (value: number, min: number, max: number) => (value - min) / (max - min)
        const seriesData = e.geneData.reduce<number[][]>((pre, cur, i) => [...pre, ...cur.map((y, j) => [j, i, y, heatMapDataNormalization ? normalization(y, minArr[i], maxArr[i]) : y])], [])
        return ({
            toolbox: {
                top: 24,
                right: 80,
                feature: {
                    dataZoom: {
                        title: {
                            zoom: 'Zoom',
                            back: 'Back'
                        }
                    },
                    restore: {
                        title: 'Restore'
                    },
                    saveAsImage: {
                        show: false
                    },
                    mySaveAsImage: {
                        title: 'Save As Image',
                        icon: 'path://M4.7,22.9L29.3,45.5L54.7,23.4M4.6,43.6L4.6,58L53.8,58L53.8,43.6M29.2,45.1L29.2,0',
                        onclick: () => exportHeatMapSvg(i)
                    }
                }
            },
            tooltip: {
                show: true,
                position: 'top'
            },
            visualMap: {
                min: heatMapDataNormalization ? 0 : Math.min(...values),
                max: heatMapDataNormalization ? 1 : Math.max(...values),
                precision: 3,
                calculable: true,
                orient: 'vertical',
                right: 0,
                top: 'center',
                textStyle: {
                    color: theme == 'dark' ? '#FFF' : '#000'
                },
                inRange: {
                    color: ["#263578", "#2C5291", "#336FAA", "#3A8CC3", "#40AADC", "#4FC3EF", "#76D0F3", "#9DDDF6", "#C4EBF9", "#EBF8FD", "#FEEEEE", "#FECCCC", "#FEABAB", "#FE8989", "#FE6868", "#FE5054", "#FE3C44", "#FE2834", "#FE1424", "#FE0014"]
                }
            },
            xAxis: {
                type: 'category',
                data: e.period,
                splitArea: {
                    show: true
                },
            },
            yAxis: {
                type: 'category',
                data: e.genes,
                splitArea: {
                    show: true
                }
            },
            series: [
                {
                    type: 'heatmap',
                    data: seriesData
                }
            ],
            grid: {
                top: 60,
                right: 80,
                bottom: 60,
                left: 120
            }
        })
    }), [exportHeatMapSvg, geneData, heatMapDataNormalization, theme]);

    const optionRight = useMemo(() => geneData.map((e, i) => {
        return ({
            toolbox: {
                top: 24,
                right: 20,
                feature: {
                    dataZoom: {
                        title: {
                            zoom: 'Zoom',
                            back: 'Back'
                        }
                    },
                    restore: {
                        title: 'Restore'
                    },
                    saveAsImage: {
                        show: false
                    },
                    mySaveAsImage: {
                        title: 'Save As Image',
                        icon: 'path://M4.7,22.9L29.3,45.5L54.7,23.4M4.6,43.6L4.6,58L53.8,58L53.8,43.6M29.2,45.1L29.2,0',
                        onclick: () => exportLinerSvg(i)
                    }
                }
            },
            tooltip: {
                show: true
            },
            title: {
                text: `${e.group}/${e.name}`,
                left: 'center',
                top: 0,
                textStyle: {
                    fontSize: 18 / columns
                }
            },
            legend: {
                top: 30,
                data: e.genes
            },
            xAxis: {
                type: 'category',
                data: e.period,
            },
            yAxis: {
                name: 'Log2(TPM)',
                type: 'value',
                scale: heatMapYAxisScale
            },
            series: e.geneData.map((data, i) => ({
                name: e.genes[i],
                type: 'line',
                data
            })),
            grid: {
                top: 60,
                right: 20,
                bottom: 60,
                left: 60
            },
        })
    }), [columns, exportLinerSvg, geneData, heatMapYAxisScale]);

    useEffect(() => {
        heatMapOptionRef.current = optionLeft
        linerOptionRef.current = optionRight
    }, [optionLeft, optionRight])

    return <div className={styles.container}>
        <div>
            <SidebarHeatMap dataTypes={[2]} onError={setErrorMsg} onQuery={setGeneData}>
                <Stack>
                    <Box style={{ margin: '8px 0 8px 4px' }}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={heatMapDataNormalization} onChange={() => setHeatMapDataNormalization(o => !o)} />} label="HeatMap Data Normalization" />
                        </FormGroup>
                    </Box>
                    <Box style={{ margin: '8px 0 8px 4px' }}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={heatMapYAxisScale} onChange={() => setHeatMapYAxisScale(o => !o)} />} label="Line Chart Y Axis Scale" />
                        </FormGroup>
                    </Box>
                    <Box style={{ margin: '8px 16px 8px 16px' }}>
                        <Typography variant="caption" gutterBottom>
                            Number of Rows
                        </Typography>
                        <Slider min={1} max={4} value={rows} onChange={(e, v) => setRows(v as number)} />
                    </Box>
                    <Box style={{ margin: '8px 16px 8px 16px' }}>
                        <Typography variant="caption" gutterBottom>
                            Number of Columns
                        </Typography>
                        <Slider min={1} max={4} value={columns} onChange={(e, v) => setColumns(v as number)} />
                    </Box>
                </Stack>
            </SidebarHeatMap>
        </div>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            {errorMsg && <div style={{ margin: 16 }}><Alert severity="info">{errorMsg}</Alert></div>}
            {geneData.length > 0 && <>
                <Scrollbars style={{ height: undefined, flex: 1 }} autoHide>
                    <Grid container>
                        {optionLeft.map((e, i) => <Grid key={i} item xs={12 / columns} sx={{ display: 'flex', flexDirection: 'row' }}>
                            <ReactECharts option={e} style={{ height: `calc(calc(100vh - 200px) / ${rows})`, width: '30%', minWidth: 300 }} notMerge={true} theme={theme} />
                            <ReactECharts option={optionRight[i]} style={{ height: `calc(calc(100vh - 200px) / ${rows})`, width: '70%' }} notMerge={true} theme={theme} />
                        </Grid>)}
                    </Grid>
                </Scrollbars>
            </>}
        </div>
    </div>
}

export default HeatMap2;