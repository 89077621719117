import { Link, Paper, Typography } from "@mui/material";
import React from "react";
import RegenomicsDataFiles from "src/component/RegenomicsDataFiles";

const ResourcePage: React.FC = () => {
  return (
    <div>
      <Paper style={{ padding: 32 }} elevation={3}>
        <Typography variant="h3" component="div" gutterBottom>
          GitHub Resources
        </Typography>
        <p>
          <Link href="https://github.com/zenglab-regeneration/DRIM">
            DRIM: https://github.com/zenglab-regeneration/DRIM
          </Link>
        </p>
        <p>
          <Link href="https://github.com/zenglab-regeneration/STAE">
            STAE: https://github.com/zenglab-regeneration/STAE
          </Link>
        </p>
      </Paper>
      <div style={{ marginTop: 32 }}>
        <RegenomicsDataFiles />
      </div>
    </div>
  );
};

export default ResourcePage;
