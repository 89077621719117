
const selectFile = (): Promise<File | undefined> => (
    new Promise<File | undefined>((reslove) => {
        const el = document.createElement('input');
        el.type = 'file';
        el.accept = "*";
        el.addEventListener('change', () => reslove(el.files?.[0]))
        el.click();
    })
)

const formatSize = (srcsize: number, fixed?: boolean): string => {
    if (srcsize <= 0) return '0B';
    const unitArr = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const index = Math.floor(Math.log(srcsize) / Math.log(1024));
    const result = (srcsize / 1024 ** index).toFixed(2);
    return (fixed ? result : parseFloat(result)) + unitArr[index];
}

export { selectFile, formatSize }