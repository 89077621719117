import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useAppSelector } from "src/service/store";

const HomePage: React.FC = () => {

    const config = useAppSelector(e => e.config);

    return <div style={{ marginBottom: 128 }}>
        <div style={{ paddingTop: 48, paddingBottom: 48 }}>
            <Typography variant="h6">
                RegeOMICS DataBase:
            </Typography>
            Regeneration Omics DataBase (RegeOMICS DB) is a comprehensive repository of datasets, bioinformatic tools, and literature related to regeneration-related omics topics. We aim to bring together the community of regeneration researchers by providing convenient tools for data search, analysis, and visualization.        </div>
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                {config.theme == 'light' && <img
                    style={{ width: '100%' }}
                    src="/images/1ec7106aa70b896f207dd29a34a634f_light.png" />}
                {config.theme != 'light' && <img
                    style={{ width: '100%' }}
                    src="/images/1ec7106aa70b896f207dd29a34a634f_dark.png" />}
            </Grid>
        </Grid>

    </div>
}

export default HomePage;