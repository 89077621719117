import React, { useEffect } from 'react';
import NavigationView from './component/NavigationVew';
import './App.scss';
import HomePage from './container/HomePage';
import { Routes, Route } from "react-router-dom";
import DataPage from './container/DataPage';
import DownloadPage from './container/DownloadPage';
import ResourcePage from './container/ResourcePage';
import LoginPage from './container/LoginPage';
import SignupPage from './container/SignupPage';
import ManagerPage from './container/ManagerPage';
import Entrance from './container/Entrance';
import MainFrame from './container/MainFrame';
import ManagerApp from './container/App'
import ThreeDPage from './container/ThreeDPage';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router';

const App: React.FC = () => {

  const navPages: IPageList = [
    { title: 'Home', path: '/', element: <HomePage /> },
    // { title: '数据', path: '/data', element: <DataPage /> },
    { title: 'Data', path: '/legacy', element: <Entrance /> },
    { title: '3DPlot', path: '/3dplot', element: <ThreeDPage /> },
    { title: 'Resource', path: '/resource', element: <ResourcePage /> },
    // { title: 'Download', path: '/download', element: <DownloadPage /> },
  ]

  const otherPages: IPageList = [
    { title: 'Login', path: '/login', element: <LoginPage /> },
    { title: 'Signup', path: '/signup', element: <SignupPage /> },
    { title: 'ControlPanel', path: 'manager/:page', element: <ManagerPage /> },
  ]

  const location = useLocation()

  useEffect(() => {
    ReactGA.pageview('#' + location.pathname);
  }, [location])

  return (
    <NavigationView pages={navPages}>
      <Routes>
        {[...navPages, ...otherPages].map(e =>
          <Route key={e.path} path={e.path} element={e.element} />
        )}
        <Route path="/manager" element={<ManagerApp />} />
        <Route path="/legacy" element={<Entrance />} />
        <Route path="/legacy/:series" element={<MainFrame />} />
        <Route path="/legacy/:series/:category" element={<MainFrame />} />
        <Route path="/legacy/:series/:category/:dataset" element={<MainFrame />} />
        <Route path="/legacy/:series/:category/:dataset/:view" element={<MainFrame />} />
      </Routes>
    </NavigationView>
  );
}

export default App;
