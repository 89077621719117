import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "src/service/store";

const AppThemeProvider: React.FC = (props) => {

    const config = useAppSelector(e => e.config);

    const theme = useMemo(() => createTheme({
        palette: {
            mode: config.theme,
        },
        typography: {
            button: {
                textTransform: 'none'
            }
        },
    }), [config.theme])

    useEffect(() => {
        const body = document.querySelector('body');
        if (!body) return;
        body.style.backgroundColor = config.theme == 'light' ? '#ffffff' : '#202020'
        body.style.color = config.theme == 'light' ? 'black' : 'white'
    }, [config.theme])

    return (
        <ThemeProvider theme={theme}>
            {props.children}
        </ThemeProvider>
    )
}

export default AppThemeProvider