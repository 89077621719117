import React, { useCallback } from "react";
import { Checkbox, Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import { useState } from "react";
import { IApiGroup, IApiItem } from "src/service/celldata";
import { useEffect } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

interface IDataPointSelection {
    items: IApiItem[]
    origIdents: Record<string, string[]>
    group: IApiGroup
    SelectionChange?: (selectedItems: Record<string, string[]>) => void
}

const timeOrder: Record<string, number> = { '0h': 0, '12h': 1, '1.5d': 2, '3d': 3, '5d': 4, '10d': 5, 'intact': 6, 'control(RNAi)': 7, 'nsf(RNAi)': 8, 'scg5(RNAi)': 9 }

const DataPointSelection: React.FC<IDataPointSelection> = ({ group, items, origIdents, SelectionChange }) => {
    const [selectedItems, setSelectedItems] = useState<Record<string, string[]>>({});
    const [open, setOpen] = useState<string[]>([])

    const toggleSelectItem = useCallback((item: IApiItem) => {
        const newSelectedItems = { ...selectedItems }
        if (selectedItems[item.id]?.length > 0 || selectedItems[item.id] && !origIdents[item.id])
            delete newSelectedItems[item.id]
        else
            Object.assign(newSelectedItems, { [item.id]: origIdents[item.id] ?? [] })
        setSelectedItems(newSelectedItems)
    }, [origIdents, selectedItems])

    const toggleSelectOrig = useCallback((item: IApiItem, orig: string) => {
        const newSelectedItems = { ...selectedItems }
        if (selectedItems[item.id]?.some(id => id == orig))
            Object.assign(newSelectedItems, { [item.id]: newSelectedItems[item.id].filter(e => e != orig) })
        else
            Object.assign(newSelectedItems, { [item.id]: [...newSelectedItems[item.id] ?? [], orig] })
        if (newSelectedItems[item.id].length == 0 && origIdents[item.id])
            delete newSelectedItems[item.id]
        setSelectedItems(newSelectedItems)
    }, [origIdents, selectedItems])

    const toggleOpen = useCallback((item: IApiItem) => {
        if (open.some(x => x == item.id))
            setOpen(open.filter(x => x != item.id))
        else
            setOpen([...open, item.id])
    }, [open])

    useEffect(() => {
        SelectionChange?.(selectedItems)
    }, [SelectionChange, selectedItems])

    useEffect(() => {
        setSelectedItems({})
    }, [group])

    return (
        <List style={{ width: 'calc(100% - 16px)', margin: 8 }} subheader={<ListSubheader>Data</ListSubheader>}>
            {items.map((item) => (<>
                {items.length > 1 && <ListItem
                    key={item.id}
                    disablePadding
                >
                    <ListItemButton onClick={() => (origIdents[item.id] ? toggleOpen : toggleSelectItem)(item)} dense>
                        <ListItemIcon>
                            <Checkbox
                                checked={!!selectedItems[item.id]}
                                indeterminate={!!selectedItems[item.id] && origIdents[item.id]?.length > selectedItems[item.id]?.length}
                                onClick={(e) => { toggleSelectItem(item); e.stopPropagation() }}
                                disableRipple
                            />
                        </ListItemIcon>
                        <ListItemText primary={item.name} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} />
                        {origIdents[item.id] && (open.some(x => x == item.id) ? <ExpandLess /> : <ExpandMore />)}
                    </ListItemButton>
                </ListItem>}
                {origIdents?.[item.id] && (<Collapse key={`${item.name}_collapse`} in={open.some(x => x == item.id) || items.length == 1} timeout="auto" unmountOnExit>
                    {origIdents[item.id].sort((a, b) => (timeOrder[a] ?? -1) - (timeOrder[b] ?? -1)).map(e => (
                        <ListItem
                            key={`${item.id}/${e}`}
                            sx={{ pl: items.length > 1 ? 4 : 0 }}
                            disablePadding
                        >
                            <ListItemButton onClick={() => toggleSelectOrig(item, e)} dense>
                                <ListItemIcon>
                                    <Checkbox
                                        checked={!!selectedItems[item.id]?.some(id => id == e)}
                                        onClick={() => toggleSelectOrig(item, e)}
                                        disableRipple
                                    />
                                </ListItemIcon>
                                <ListItemText primary={e} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </Collapse>)}
            </>))}
        </List>
    )
};

export default DataPointSelection

export { timeOrder }