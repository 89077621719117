import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApiGroup, IApiItem } from '../service/celldata'

interface ICategory {
    img: string,
    title: string,
    name: string,
    description: string
}

interface IView {
    name: 'Violin plot' | 'Feature plot' | 'Cluster plot' | 'Heatmap',
    img: string,
    type: number[]
}

interface IDataset {
    categorys: ICategory[],
    groups: IApiGroup[],
    views: IView[],
    items: IApiItem[],
}

const initialState: IDataset = {
    categorys: [
        {
            img: './images/planarian.png',
            title: 'PRSTA',
            name: 'PRSTA',
            description: '',
        },
        // {
        //     img: './images/mouseheart.png',
        //     title: 'Mouse',
        //     name: 'mouse',
        //     description: ''
        // }
    ],
    groups: [],
    views: [
        {
            name: 'Violin plot',
            img: '/images/violin.png',
            type: [0]
        },
        {
            name: 'Feature plot',
            img: '/images/umap.png',
            type: [0, 1]
        },
        {
            name: 'Cluster plot',
            img: '/images/clusterLegend.png',
            type: [0, 1]
        },
        {
            name: 'Heatmap',
            img: '/images/heatMap.png',
            type: [2]
        },
    ],
    items: []
}

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setGroups: (state, action: PayloadAction<typeof initialState.groups>) => {
            state.groups = action.payload;
        },
        setItems: (state, action: PayloadAction<typeof initialState.items>) => {
            state.items = action.payload;
        }
    }
});

export const { setGroups, setItems } = configSlice.actions;
export default configSlice.reducer;
export type { ICategory, IView }