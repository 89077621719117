import { DefaultButton, Dialog, DialogFooter, PrimaryButton } from '@fluentui/react';
import React from 'react';
import './index.scss'

interface IConfirmDialog {
    title: string
    subText?: string
    hideDialog: boolean
    errorMessage?: string
    toggleHideDialog: () => void
    toggleConfirm: () => void
}

const ConfirmDialog: React.FC<IConfirmDialog> = (props) => {

    return (
        <>
            <Dialog
                hidden={props.hideDialog}
                onDismiss={props.toggleHideDialog}
                dialogContentProps={{
                    title: props.title,
                    subText: props.subText,
                }}
            >
                <p style={{ fontSize: 12, color: 'rgb(164, 38, 44)' }}>{props.errorMessage}</p>
                <DialogFooter>
                    <PrimaryButton onClick={props.toggleConfirm} text="确定" />
                    <DefaultButton onClick={props.toggleHideDialog} text="取消" />
                </DialogFooter>
            </Dialog>
        </>
    );
}

export default ConfirmDialog