import { Container, Stack, Typography } from '@mui/material';
import React from 'react';
import { useAppSelector } from 'src/service/store';
import styles from './index.module.scss'
import ItemCard from './ItemCard';

const Entrance: React.FC = () => {
    const categorys = useAppSelector(e => e.dataset.categorys);

    return <div className={styles.container}>
        <div className={styles.wrapper} />
        <div className={styles.content}>
            <div className={styles.header}>
                <Typography variant="h3" sx={{ mt: 4, mb: -4 }}>
                    Series
                </Typography>
            </div>
            <div className={styles.options}>
                <div style={{ flex: 0.5 }} />
                <Stack direction={'row'} spacing={8}>
                    {categorys.map(item => <ItemCard key={item.name} {...item} />)}
                </Stack>
                <div style={{ flex: 1 }} />
            </div>
        </div>
    </div>
}

export default Entrance;