import {
  Alert,
  Breadcrumbs,
  Button,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  IApiLoadRegenomicsData,
  loadRegenomicsData,
} from "src/service/celldata";

import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const RegenomicsDataFiles: React.FC = () => {
  const [data, setData] = useState<IApiLoadRegenomicsData>();
  const [password, setPassword] = useState("");
  const [errmsg, setErrmsg] = useState("");
  const [path, setPath] = useState<string[]>([]);

  const loadData = async () => {
    const ret = await loadRegenomicsData(password);
    if (ret.code == 0) {
      setData(ret.data);
    } else setErrmsg(ret.message);
  };

  let base = (data?.base ?? "") + (path?.join("/") ?? "");
  if (!base.endsWith("/")) base += "/";
  const items = Array.from(
    new Set(
      data?.urls
        .filter((x) => x.startsWith(base))
        .map((x) => {
          const last = x.slice(base.length);
          if (last.indexOf("/") < 0) return last;
          return last.slice(0, last.indexOf("/"));
        })
    )
  )
    .map((x) => ({
      name: x,
      fullPath: base + x,
      isFile: data?.urls.some((y) => y === base + x),
    }))
    .sort((a, b) => {
      if (!a.isFile) return -1;
      if (!b.isFile) return 1;
      return 0;
    });

  const onItemClick = (item: {
    name: string;
    fullPath: string;
    isFile?: boolean;
  }) => {
    if (item.isFile) {
      window.open(item.fullPath);
    } else {
      setPath([...path, item.name]);
    }
  };

  return (
    <Paper style={{ padding: 32 }} elevation={3}>
      <Typography variant="h3" component="div" gutterBottom>
        Regenomics Data
      </Typography>
      {data && (
        <div>
          <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: 16 }}>
            {path.slice(0, -1).map((name, i) => (
              <Link
                key={name}
                underline="hover"
                color="inherit"
                style={{ cursor: "pointer" }}
                onClick={() => setPath(path.slice(0, i + 1))}
              >
                {name}
              </Link>
            ))}
            <Typography color="text.primary">
              {path.length > 0 ? path[path.length - 1] : "/"}
            </Typography>
          </Breadcrumbs>
          <List>
            {path.length > 0 && (
              <ListItem disablePadding>
                <ListItemButton onClick={() => setPath(path.slice(0, -1))}>
                  <ListItemIcon>
                    <ArrowUpwardIcon />
                  </ListItemIcon>
                  <ListItemText primary=".." />
                </ListItemButton>
              </ListItem>
            )}
            {items?.map((item) => (
              <ListItem key={item.name} disablePadding>
                {!item.isFile && (
                  <ListItemButton onClick={() => onItemClick(item)}>
                    <ListItemIcon>
                      <FolderIcon />
                    </ListItemIcon>
                    <ListItemText primary={decodeURIComponent(item.name)} />
                  </ListItemButton>
                )}
                {item.isFile && (
                  <ListItem>
                    <ListItemIcon>
                      <InsertDriveFileIcon />
                    </ListItemIcon>
                    <Link variant="body1" href={(data.map[item.fullPath] ?? item.fullPath)} target="_blank">
                      {decodeURIComponent(item.name)}
                    </Link>
                  </ListItem>
                )}
              </ListItem>
            ))}
          </List>
          <p style={{ marginLeft: 16, marginTop: 8 }}>
            Note:
            <br />
            If you cannot download the data , there are two options:
            <br />
            1. Please clear the cache of this website, change the address to
            http://db.regeomics.cn/#/resource (not https) and try again. <br />
            2. Or you can directly copy the address by right clicking the file
            label, and paste it to the address bar to download.
            <br />
          </p>
        </div>
      )}
      {!data && (
        <>
          <div>
            Here is the file structure and URLs of all the data newly generated
            in the paper. For security reasons, files could only be visited and
            downloaded in specific URLs.
          </div>
          <div style={{ marginTop: 16, display: "flex", gap: 8 }}>
            <TextField
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label="Password"
              variant="outlined"
              type="password"
            />
            <Button
              variant="contained"
              style={{ minWidth: 100 }}
              onClick={loadData}
            >
              Enter
            </Button>
            {errmsg && (
              <Alert
                severity="error"
                style={{ display: "flex", alignItems: "center" }}
              >
                {errmsg}
              </Alert>
            )}
          </div>
        </>
      )}
    </Paper>
  );
};

export default RegenomicsDataFiles;
