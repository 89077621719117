import { Card, Stack } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './index.module.scss';

interface IItemCard {
    name: string,
    series: string
}

const ItemCard: React.FC<IItemCard> = (props) => {
    const navigate = useNavigate();
    const params = useParams();

    const navigateToItem = async () => {
        navigate(
            '/legacy' +
            '/' + encodeURIComponent(params.series ?? '') +
            '/' + encodeURIComponent(props.name ?? '')
        )
    }

    return <Card className={styles.card} onClick={navigateToItem}>
        <Stack>
            <div style={{ margin: 16, textAlign: 'center' }}>
                <div style={{ fontSize: 24 }}>{props.name}</div>
            </div>
        </Stack>
    </Card>
}

export default ItemCard;