import React, { useState } from 'react'
import Sidebar from 'src/container/Sidebars/Sidebar'
import styles from './index.module.scss'

const App: React.FC = () => {
    // const [selectedData, setSelectedData] = useState<IApiItem[]>([]);

    return <div className={styles.container}>
        <Sidebar onSelectionChange={() => {/**/ }} />
        {/* <Content selectedData={selectedData} /> */}
    </div>
}

export default App