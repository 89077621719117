import styled from "@emotion/styled";
import { Button } from "@mui/material";


const AppBarButton = styled(Button)({
    '&:hover': {
        backgroundColor: '#FFFFFF10'
    }
});

export { AppBarButton }


