import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router";
import { Scrollbars } from 'react-custom-scrollbars';
import styles from './index.module.scss'
import { useAppSelector } from "src/service/store";
import { Autocomplete, Checkbox, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { IApiItem, IApiQueryItem, queryData, queryRowData } from "src/service/celldata";
import { useEffect } from "react";
import { useRef } from "react";
import DataPointSelection from "src/component/DataPointSelectrion";
import { IGeneData } from "src/component/GeneSelection";

interface ISidebar2 {
    children?: JSX.Element,
    dataTypes: number[],
    onQuery?: (data: IGeneData[], gene?: string) => void
    onError?: (msg: string) => void
}

const Sidebar2: React.FC<ISidebar2> = (props) => {
    const params = useParams();
    const allItems = useAppSelector(e => e.dataset.items);
    const groups = useAppSelector(e => e.dataset.groups);
    const group = useMemo(() => groups.filter(e => e.name == params.series + '/' + params.category + '/' + params.dataset)[0], [groups, params.category, params.dataset, params.series]);
    const items = useMemo(() => group ? allItems.slice(group.startIndex, group.startIndex + group.count).filter(e => props.dataTypes.indexOf(e.type) > -1) : [], [allItems, group, props.dataTypes]);
    const [selectItems, setSelectItems] = useState<Record<string, string[]>>({});
    const [gene, setGene] = useState('SMED30007406');
    const timerRef = useRef<NodeJS.Timeout>();
    const propsRef = useRef(props);
    const [allGeneIds, setAllGeneIds] = useState<string[]>([]);
    const [origIdents, setOrigIdents] = useState<Record<string, string[]>>({});
    const [origIdentIndexs, setOrigIdentIndexs] = useState<Record<string, Record<string, number[]>>>({});
    const toggleQueryDataRef = useRef<() => void>(() => { /** */ });
    const [labels, setLabels] = useState<string[]>([]);
    const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
    const [apiQueryItems, setApiQueryItems] = useState<IApiQueryItem[]>();
    const onDataChangeRef = useRef<(apiQueryItems: IApiQueryItem[]) => void>()

    useEffect(() => {
        propsRef.current = props
    }, [props])

    useEffect(() => {
        queryData(items, 'orig.ident').then(res => {
            if (res.code == 0) {
                const map: Record<string, string[]> = {}
                const IndexMap: Record<string, Record<string, number[]>> = {}
                res.data.forEach((e, i) => {
                    const { id } = items[i];
                    const arr = e.geneData.split('\n').filter(e => e != '')
                    Object.assign(map, { [id]: Array.from(new Set(arr)) })
                    Object.assign(IndexMap, { [id]: {} })
                    map[id].forEach(e => Object.assign(IndexMap[id], { [e]: [] }))
                    arr.forEach((e, i) => IndexMap[id][e].push(i))
                })
                setOrigIdents(map)
                setOrigIdentIndexs(IndexMap)
            }
        });
    }, [items])

    const queryItems = useMemo(() => Object.keys(selectItems).map(x => items.find(y => y.id == x) as IApiItem).filter(e => e), [items, selectItems])

    const onDataChange = useCallback((apiQueryItems: IApiQueryItem[]) => {
        if (!selectItems) return
        const data: IGeneData[] = []
        apiQueryItems.forEach((e, i) => {
            const queryItem = queryItems[i]
            if (!queryItem) return
            const selectedOrig = selectItems[queryItem.id]
            const x = e.x.split('\n').slice(0, -1).map(e => parseFloat(e))
            const y = e.y.split('\n').slice(0, -1).map(e => parseFloat(e))
            const geneData = e.geneData.split('\n').slice(0, -1).map(e => parseFloat(e))
            const cellType = e.cellType.split('\n').slice(0, -1)
            if (selectedOrig.length == 0) {
                data.push({ group: queryItem.group, name: queryItem.name, gene, x, y, geneData, cellType })
            } else if (origIdents[queryItem.id]) {
                const labelSet = new Set(selectedLabels)
                selectedOrig
                    .map(origIdent => ({
                        origIdent,
                        indexs: origIdentIndexs[queryItem.id][origIdent].filter(e => labelSet.has(cellType[e]))
                    }))
                    .forEach(({ origIdent, indexs }) => {
                        data.push({
                            group: queryItem.group,
                            name: queryItem.name,
                            gene,
                            origIdent,
                            x: indexs.map(i => x[i]),
                            y: indexs.map(i => y[i]),
                            geneData: indexs.map(i => geneData[i]),
                            cellType: indexs.map(i => cellType[i]),
                        })
                    })
            }
        })
        propsRef.current.onQuery?.(data, gene)
    }, [gene, origIdentIndexs, origIdents, queryItems, selectItems, selectedLabels]);

    useEffect(() => {
        onDataChangeRef.current = onDataChange
    }, [onDataChange])

    useEffect(() => apiQueryItems && onDataChangeRef.current?.(apiQueryItems), [apiQueryItems, selectedLabels])

    const toggleQueryData = useCallback(async () => {
        queryRowData(queryItems, []).then(res => {
            if (res.code == 0)
                setAllGeneIds(Array.from(new Set(res.data.reduce<string[]>((pre, cur) => [...pre, ...cur.name.split('\n')], []).filter(e => e.startsWith('SMED')))))
        });
        let err: string | undefined = undefined
        if (gene.trim() == '') {
            err = "Please Input Gene ID";
        }
        if (items.length == 0) {
            err = "No Data";
        }
        if (queryItems.length == 0) {
            err = "Please Select Data";
        }
        if (err) {
            propsRef.current.onError?.(err);
            setLabels([])
            setSelectedLabels([])
            return;
        }
        const res = await queryData(queryItems, gene)
        if (res.code == 0) {
            try {
                const labelSet = new Set<string>()
                res.data.forEach((e, i) => {
                    const cellType = e.cellType.split('\n').slice(0, -1)
                    cellType.forEach(e => labelSet.add(e))
                })
                setApiQueryItems(res.data)
                setLabels(Array.from(labelSet))
                setSelectedLabels(old => old.length == 0 ? Array.from(labelSet) : old)
            } catch (e) {
                propsRef.current.onError?.("数据出现错误或损坏")
            }
        } else {
            propsRef.current.onError?.(res.message)
        }
    }, [gene, items.length, queryItems]);

    const toggleSelectLabel = useCallback((label: string) =>
        setSelectedLabels(selectedLabels => {
            if (selectedLabels.some(e => e == label))
                return selectedLabels.filter(e => e != label)
            else
                return [...selectedLabels, label]
        }), [])

    useEffect(() => {
        toggleQueryDataRef.current = toggleQueryData
    }, [toggleQueryData])

    useEffect(() => {
        timerRef.current = setTimeout(() => toggleQueryDataRef.current(), 500)
        return () => timerRef.current && clearTimeout(timerRef.current)
    }, [selectItems])

    useEffect(() => {
        timerRef.current = setTimeout(() => toggleQueryDataRef.current(), 1000)
        return () => timerRef.current && clearTimeout(timerRef.current)
    }, [gene])

    useEffect(() => {
        setSelectItems({})
    }, [group])

    const autocompleteOptions = [...allGeneIds.filter(x => x.indexOf(gene) > -1), ...allGeneIds.filter(x => x.indexOf(gene) == -1)]
    if (autocompleteOptions.indexOf(gene) == -1) autocompleteOptions.push(gene)
    return (
        <div className={styles.container}>
            <div style={{ flex: 1 }}>
                <Scrollbars autoHide>
                    <Autocomplete
                        disablePortal
                        options={autocompleteOptions.slice(0, 100)}
                        value={gene}
                        onChange={(e, v) => { setGene(v ?? ''); clearTimeout(timerRef.current); toggleQueryDataRef.current() }}
                        renderInput={(params) => <TextField
                            onChange={(e) => setGene(e.target.value)}
                            {...params}
                            style={{ width: 'calc(100% - 32px)', margin: '16px 16px 0 16px' }}
                            label="Gene ID"
                            variant="outlined"
                        />}
                    />
                    <DataPointSelection group={group} items={items} origIdents={origIdents ?? {}} SelectionChange={setSelectItems} />
                    {labels.length > 0 && <List style={{ width: 'calc(100% - 16px)', margin: 8 }}
                        subheader={<ListSubheader>Labels</ListSubheader>}>
                        {labels.map((item) => (<>
                            <ListItem
                                key={item}
                                disablePadding
                            >
                                <ListItemButton onClick={() => toggleSelectLabel(item)} dense>
                                    <ListItemIcon>
                                        <Checkbox disableRipple checked={selectedLabels.some(e => e == item)} />
                                    </ListItemIcon>
                                    <ListItemText primary={item} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} />
                                </ListItemButton>
                            </ListItem>
                        </>))}
                    </List>}
                </Scrollbars >
            </div>
            {props.children && <>
                <Divider />
                <Stack direction={'column'} style={{ width: 'calc(100% - 16px)', margin: 8 }} >
                    {props.children}
                </Stack>
            </>}
        </div>
    )
}

export default Sidebar2;