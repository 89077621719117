import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import styles from './index.module.scss'
import { useAppDispatch, useAppSelector } from 'src/service/store';
import { useNavigate, useParams } from 'react-router';
import { getDataList } from 'src/service/celldata';
import { setGroups, setItems } from 'src/model/dataset';
import { useMemo } from 'react';

const Navigation: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const params = useParams();
    const allItems = useAppSelector(e => e.dataset.items);
    const { groups, views } = useAppSelector(e => e.dataset);
    const group = groups.filter(e => e.name == params.series + '/' + params.category + '/' + params.dataset)[0];
    const items = group ? allItems.slice(group.startIndex, group.startIndex + group.count) : []
    const dataTypeSet = new Set(items.map(e => e.type))
    const supportViews = views.filter(e => e.type.some(t => dataTypeSet.has(t)))

    const loadDataList = useCallback(async () => {
        const { code, data } = await getDataList()
        if (code != 0) return
        dispatch(setGroups(data.groups))
        dispatch(setItems(data.items));
    }, [dispatch])

    useEffect(() => {
        loadDataList()
    }, [loadDataList])

    const series = useMemo(() => {
        return Array.from(new Set(groups.map(e => e.name.split('/')[0])))
    }, [groups])

    const categorys = useMemo(() => {
        const start = params.series + '/';
        return Array.from(new Set(groups.filter(e => e.name.startsWith(start)).map(e => e.name.slice(start.length).split('/')[0])))
    }, [groups, params.series])

    const categoryGroups = useMemo(() => {
        const start = params.series + '/' + params.category + '/';
        return groups.filter(e => e.name.startsWith(start)).map(e => e.name.slice(start.length))
    }, [groups, params.category, params.series])

    useEffect(() => {
        if (params.dataset && params.view && supportViews.every(e => e.name != params.view)) {
            navigate(
                '/legacy' +
                '/' + encodeURIComponent(params.series ?? '') +
                '/' + encodeURIComponent(params.category ?? '') +
                '/' + encodeURIComponent(params.dataset))
        }
    }, [navigate, params.category, params.dataset, params.series, params.view, supportViews]);

    return (<div className={styles.container}>

        <FormControl style={{ width: 180 }}>
            <InputLabel id="label0">Series</InputLabel>
            <Select
                labelId="label0"
                label="Series"
                value={params.series ?? ''}
                onChange={e => navigate('/legacy/' + encodeURIComponent(e.target.value))}
            >
                {series.map(e => <MenuItem key={e} value={e}>{e}</MenuItem>)}
            </Select>
        </FormControl>
        <FormControl style={{ width: 280 }}>
            <InputLabel id="label1">Category</InputLabel>
            <Select
                labelId="label1"
                label="Category"
                value={params.category ?? ''}
                onChange={e => navigate(
                    '/legacy' +
                    '/' + encodeURIComponent(params.series ?? '') +
                    '/' + encodeURIComponent(e.target.value))}
            >
                {categorys.map(e => <MenuItem key={e} value={e}>{e}</MenuItem>)}
            </Select>
        </FormControl>
        <FormControl style={{ width: 280 }}>
            <InputLabel id="label2">Data Set</InputLabel>
            <Select
                labelId="label2"
                label="Data Set"
                disabled={!params.series || !params.category}
                value={params.dataset ?? ''}
                onChange={e => navigate(
                    '/legacy' +
                    '/' + encodeURIComponent(params.series ?? '') +
                    '/' + encodeURIComponent(params.category ?? '') +
                    '/' + encodeURIComponent(e.target.value) +
                    '/' + encodeURIComponent(params.view ?? ''))}
            >
                {categoryGroups.map(e => <MenuItem key={e} value={e}>{e}</MenuItem>)}
            </Select>
        </FormControl>
        <FormControl style={{ width: 180 }}>
            <InputLabel id="label3">View</InputLabel>
            <Select
                labelId="label3"
                label="View"
                disabled={!params.dataset || !params.category}
                value={supportViews.map(e => e.name).some(e => e == params.view) ? params.view : ''}
                onChange={e => navigate(
                    '/legacy' +
                    '/' + encodeURIComponent(params.series ?? '') +
                    '/' + encodeURIComponent(params.category ?? '') +
                    '/' + encodeURIComponent(params.dataset ?? '') +
                    '/' + encodeURIComponent(e.target.value))}
            >
                {supportViews.map(e => <MenuItem key={e.name} value={e.name}>{e.name}</MenuItem>)}
            </Select>
        </FormControl>

    </div>)
}

export default Navigation;