import { Alert, Box, Grid, Slider, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import ReactECharts from 'echarts-for-react';
import { useLocalStorage } from "src/service/hooks";
import styles from './index.module.scss'
import SidebarClusterLegend, { IClusterLegendData } from "../Sidebars/SidebarClusterLegend";
import { partialPathConverter } from "src/utility/common";
import { useAppSelector } from "src/service/store";
import { downloadTxt, optionToSvg } from "src/service/echarts";
import { timeOrder } from "src/component/DataPointSelectrion";

const colors = ['#959595', '#da3b3d', '#9e76c3', '#2dc3d4', '#43aade', '#7b9b60', '#e7b64c', '#fb6043']

const colorsMap: Record<string, string> = {
    'tgs1+-Neoblast': "#1f78b4",
    'Nb2': "#1f78b4", 'Gut': "#34942e",
    'Neural': "#e31a1c", 'Epidermal': "#fa9614",
    'Muscle': "#8832b3", 'Pharynx': "#e9ed00",
    'Protonephridia': "#a64d1c",
    'cathe+-cells': "#696969",
    'cathepsin+ cells': "#696969",
    'Parenchymal': "#00FFFF"
}

const ClusterLegend2: React.FC = () => {
    const [errorMsg, setErrorMsg] = useState<string>();
    const [geneData, setGeneData] = useState<IClusterLegendData[]>([]);
    const [symbolSize, setSymbolSize] = useLocalStorage('ClusterLegendSymbolSize', 4);
    const [columns, setColumns] = useLocalStorage('ClusterLegendColumns', 1);
    const groups = useMemo(() => geneData.map(e => e.group), [geneData])
    const names = useMemo(() => geneData.map(e => e.name), [geneData])
    const [aspect, setAspect] = useLocalStorage('ClusterLegendAspect', 1);
    const optionRef = useRef<any>()

    const theme = useAppSelector(e => e.config.theme);

    useEffect(() => {
        if (geneData.length > 0) {
            setErrorMsg(undefined)
        }
    }, [geneData])

    useEffect(() => {
        if (errorMsg) {
            setGeneData([])
        }
    }, [errorMsg])

    const typeArr = useMemo(() => geneData.map(e => {
        const tmp = new Set<string>()
        e.cellType.forEach(t => tmp.add(t))
        return Array.from(tmp).sort();
    }), [geneData])

    const cluster = useMemo(() => geneData.map((e, i) => {
        const tmp = new Map<string, number[][]>()
        typeArr[i].forEach(t => tmp.set(t, []))
        e.cellType.forEach((t, i) => tmp.get(t)?.push([e.x[i], e.y[i]]))
        return tmp;
    }), [geneData, typeArr])

    const exportSvg = useCallback(async (i: number) => {
        downloadTxt(optionRef.current[i].title.text + '.svg', optionToSvg(optionRef.current[i], theme, 1200, 800));
    }, [theme])

    const option = useMemo(() => geneData.map((e, i) => {
        let minX = Math.min(...e.x)
        let maxX = Math.max(...e.x)
        let minY = Math.min(...e.y)
        let maxY = Math.max(...e.y)
        const paddingX = (maxX - minX) * 0.1
        const paddingY = (maxY - minY) * 0.1
        minX -= paddingX;
        maxX += paddingX;
        minY -= paddingX;
        maxY += paddingY;
        if ((maxX - minX) / (maxY - minY) > aspect) {
            const extendY = ((maxX - minX) / aspect - (maxY - minY)) / 2;
            minY -= extendY;
            maxY += extendY;
        } else {
            const extendX = ((maxY - minY) * aspect - (maxX - minX)) / 2;
            minX -= extendX;
            maxX += extendX;
        }
        return ({
            toolbox: {
                right: 48,
                feature: {
                    dataZoom: {
                        title: {
                            zoom: 'Zoom',
                            back: 'Back'
                        }
                    },
                    restore: {
                        title: 'Restore'
                    },
                    saveAsImage: {
                        show: false
                    },
                    mySaveAsImage: {
                        title: 'Save As Image',
                        icon: 'path://M4.7,22.9L29.3,45.5L54.7,23.4M4.6,43.6L4.6,58L53.8,58L53.8,43.6M29.2,45.1L29.2,0',
                        onclick: () => exportSvg(i)
                    }
                }
            },
            tooltip: {
                show: true,
                formatter: '{a}',
                axisPointer: {
                    type: 'cross'
                }
            },
            axisPointer: {
                label: {
                    backgroundColor: theme == 'dark' ? '#666' : '#999',
                }
            },
            title: {
                text: partialPathConverter([groups, names], [e.group, e.name, e.origIdent]),
                left: 'center',
                top: 20,
                textStyle: {
                    fontSize: Math.max(18 / columns, 12)
                }
            },
            xAxis: {
                scale: true,
                min: minX,
                max: maxX,
                axisLabel: {
                    showMaxLabel: false,
                    showMinLabel: false,
                }
            },
            yAxis: {
                scale: true,
                min: minY,
                max: maxY,
                axisLabel: {
                    showMaxLabel: false,
                    showMinLabel: false,
                }
            },
            series: Array.from(cluster[i].keys()).map((type, j) => ({
                color: colorsMap[type] ?? colors[j],
                name: type,
                type: 'scatter',
                data: cluster[i].get(type),
                symbolSize: symbolSize / columns,
            })),
            legend: {
                data: Array.from(cluster[i].keys()),
                orient: 'vertical',
                top: 'center',
                right: 32
            },
            grid: {
                top: 60,
                right: 190,
                bottom: 40,
                left: 60
            }
        })
    }), [aspect, cluster, columns, exportSvg, geneData, groups, names, symbolSize, theme]);

    useEffect(() => {
        optionRef.current = option
    }, [option])

    return <div className={styles.container}>
        <div>
            <SidebarClusterLegend dataTypes={[0, 1]} onError={setErrorMsg} onQuery={d => setGeneData(d
                .sort((a, b) => a.group.localeCompare(b.group))
                .sort((a, b) => a.name.localeCompare(b.name))
                .sort((a, b) => (timeOrder[a.origIdent ?? ''] ?? -1) - (timeOrder[b.origIdent ?? ''] ?? -1)))}>
                <Stack>
                    <Box style={{ margin: '8px 16px 8px 16px' }}>
                        <Typography variant="caption" gutterBottom>
                            Number of Columns
                        </Typography>
                        <Slider min={1} max={3} value={columns} onChange={(e, v) => setColumns(v as number)} />
                    </Box>
                    <Box style={{ margin: '8px 16px 8px 16px' }}>
                        <Typography variant="caption" gutterBottom>
                            Mark Size
                        </Typography>
                        <Slider min={1} max={16} value={symbolSize} onChange={(e, v) => setSymbolSize(v as number)} />
                    </Box>
                    <Box style={{ margin: '8px 16px 8px 16px' }}>
                        <Typography variant="caption" gutterBottom>
                            Aspect ratio
                        </Typography>
                        <Slider min={0.5} max={2} step={0.1} value={aspect} onChange={(e, v) => setAspect(v as number)} />
                    </Box>
                </Stack>
            </SidebarClusterLegend>
        </div>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            {errorMsg && <div style={{ margin: 16 }}><Alert severity="info">{errorMsg}</Alert></div>}
            {geneData.length > 0 && <>
                <Scrollbars style={{ height: undefined, flex: 1 }} autoHide>
                    <Grid container minWidth={columns * 380}>
                        {option.map((e, i) => <Grid key={i} item xs={12 / columns}>
                            <ReactECharts option={e} style={{ height: `calc(calc(100vh - 250px) / ${columns})`, minHeight: 700 / columns }} notMerge={true} theme={theme} />
                        </Grid>)}
                    </Grid>
                </Scrollbars>
            </>}
        </div>
    </div>
}

export default ClusterLegend2;