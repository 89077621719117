import { CommandBarButton, GroupedList, IGroup, SelectionMode, Stack, Selection, SelectionZone, DetailsRow, IGroupHeaderProps, GroupHeader, IconButton, IObjectWithKey, Label } from '@fluentui/react'
import { useBoolean, useConst } from '@fluentui/react-hooks'
import { Scrollbars } from 'react-custom-scrollbars';
import Card from 'src/component/Card'
import { InputDialog } from 'src/component/Dialog'
import ConfirmDialog from 'src/component/Dialog/ConfirmDialog'
import Upload from 'src/component/Upload'
import React, { useCallback, useEffect, useState } from 'react'
import { createGroup, deleteData, deleteGroup, getDataList, IApiItem, moveDataPos } from 'src/service/celldata'
import styles from './index.module.scss'
import ItemCommandBar from 'src/component/ItemCommandBar';

const columns = [
    { key: 'name', name: 'name', fieldName: 'name', isRowHeader: true, minWidth: 100 },
    { key: 'typeName', name: 'typeName', fieldName: 'typeName', minWidth: 100 },
    { key: 'commandBar', name: 'commandBar', fieldName: 'commandBar', minWidth: 100 },
]

interface ISidebar {
    onSelectionChange?: (selection: IApiItem[]) => void
}

const Sidebar: React.FC<ISidebar> = (props) => {
    const [hideCreateGroupDialog, hideCreateGroupDialogCallback] = useBoolean(true)
    const [hideDeleteGroupDialog, hideDeleteGroupDialogCallback] = useBoolean(true)
    const [hideDeleteDataDialog, hideDeleteDataDialogCallback] = useBoolean(true)
    const [hideUploadDialog, hideUploadDialogCallback] = useBoolean(true)
    const [createGroupDialogErrorMessage, setCreateGroupDialogErrorMessage] = useState<string>()
    const [deleteGroupDialogErrorMessage, setDeleteGroupDialogErrorMessage] = useState<string>()
    const [deleteDataDialogErrorMessage, setDeleteDataDialogErrorMessage] = useState<string>()
    const [items, setItems] = useState<IObjectWithKey[]>([])
    const [groups, setgGroups] = useState<IGroup[]>([])
    const [selectedGroup, setSelectedGroup] = useState<string>()
    const [selectedData, setSelectedData] = useState<IApiItem>()
    const selection: Selection = useConst(() => new Selection({
        onSelectionChanged: () => props.onSelectionChange?.(selection.getSelection() as unknown as IApiItem[])
    }))

    const loadDataList = useCallback(async () => {
        const { code, data } = await getDataList()
        if (code != 0) return
        setItems(data.items.map(e => ({
            key: e.name,
            ...e,
            commandBar: <ItemCommandBar
                toggleDelete={() => {
                    setSelectedData(e)
                    hideDeleteDataDialogCallback.setFalse()
                }}
                toggleMoveUp={async () => {
                    await moveDataPos(e.group, e.name, -1)
                    loadDataList()
                }}
                toggleMoveDown={async () => {
                    await moveDataPos(e.group, e.name, 1)
                    loadDataList()
                }}
            />
        })))
        setgGroups(data.groups.map(e => ({ key: e.name, ...e })))
    }, [hideDeleteDataDialogCallback])

    const toggleCreateGroup = useCallback(async (name: string) => {
        const res = await createGroup(name)
        if (res.code == 0) {
            hideCreateGroupDialogCallback.setTrue()
            loadDataList()
        } else {
            setCreateGroupDialogErrorMessage(res.message)
        }
    }, [hideCreateGroupDialogCallback, loadDataList])


    const toggleDeleteGroup = useCallback(async (name: string) => {
        const res = await deleteGroup(name)
        if (res.code == 0) {
            loadDataList()
            hideDeleteGroupDialogCallback.setTrue()
        } else {
            setDeleteGroupDialogErrorMessage(res.message)
        }
    }, [hideDeleteGroupDialogCallback, loadDataList])

    const toggleDeleteData = useCallback(async (item: IApiItem) => {
        const res = await deleteData(item.group, item.name)
        if (res.code == 0) {
            loadDataList()
            hideDeleteDataDialogCallback.setTrue()
        } else {
            setDeleteDataDialogErrorMessage(res.message)
        }
    }, [hideDeleteDataDialogCallback, loadDataList])

    useEffect(() => {
        hideCreateGroupDialog && setCreateGroupDialogErrorMessage(undefined)
    }, [hideCreateGroupDialog])

    useEffect(() => {
        hideDeleteGroupDialog && setDeleteGroupDialogErrorMessage(undefined)
    }, [hideDeleteGroupDialog])

    useEffect(() => {
        loadDataList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        selection.setItems(items);
    }, [items, selection])

    const onRenderTitle = (props?: IGroupHeaderProps) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Label style={{ lineHeight: '32px', marginLeft: 8 }}>{props?.group?.name}</Label>
            <Stack verticalAlign='center' className={'groupCommandBar'} style={{ marginRight: 8 }} horizontal>
                <IconButton iconProps={{ iconName: 'Add' }} title="上传文件" onClick={e => {
                    e.stopPropagation();
                    setSelectedGroup(props?.group?.name);
                    hideUploadDialogCallback.setFalse();
                }} />
                <IconButton iconProps={{ iconName: 'Delete' }} title="删除分组" onClick={e => {
                    e.stopPropagation();
                    setSelectedGroup(props?.group?.name)
                    hideDeleteGroupDialogCallback.setFalse()
                }} />
            </Stack>
        </div>
    );

    const groupProps = {
        showEmptyGroups: true,
        onRenderHeader: (props?: IGroupHeaderProps): JSX.Element => (
            <GroupHeader onRenderTitle={onRenderTitle} {...props} />
        )
    }

    const onRenderCell = (
        nestingDepth?: number,
        item?: IObjectWithKey,
        itemIndex?: number,
        group?: IGroup,
    ): React.ReactNode => {
        return item && typeof itemIndex === 'number' && itemIndex > -1 ? (
            <DetailsRow
                columns={columns}
                groupNestingDepth={nestingDepth}
                item={item}
                itemIndex={itemIndex}
                selection={selection}
                selectionMode={SelectionMode.multiple}
                group={group}
            />
        ) : null;
    };

    return <div className={styles.sidebar}>
        <div className={styles.title}>
            数据列表
        </div>
        <Card className={styles.card}>
            <Stack horizontal className={styles.commandBarStack}>
                <CommandBarButton
                    iconProps={{ iconName: 'Add' }}
                    text="新建分组"
                    onClick={hideCreateGroupDialogCallback.setFalse}
                />
                <CommandBarButton
                    iconProps={{ iconName: 'Upload' }}
                    text="文件上传"
                    disabled={groups.length == 0}
                    onClick={() => {
                        if (selectedGroup == undefined || groups.every(e => e.key != selectedGroup))
                            setSelectedGroup(groups[0].key)
                        hideUploadDialogCallback.setFalse()
                    }}
                />
            </Stack>
            <Scrollbars style={{ height: undefined, flex: 1 }} autoHide>
                {selection && <SelectionZone selection={selection} selectionMode={SelectionMode.multiple}>
                    <GroupedList
                        className={styles.groupedList}
                        items={items}
                        groups={groups}
                        onRenderCell={onRenderCell}
                        groupProps={groupProps}
                        selectionMode={SelectionMode.multiple}
                        selection={selection}
                    />
                </SelectionZone>}
            </Scrollbars>
        </Card>
        <InputDialog
            title='新建分组'
            label='分组名称'
            toggleConfirm={toggleCreateGroup}
            hideDialog={hideCreateGroupDialog}
            toggleHideDialog={hideCreateGroupDialogCallback.setTrue}
            errorMessage={createGroupDialogErrorMessage}
            onValueChange={() => setCreateGroupDialogErrorMessage(undefined)}
        />
        <ConfirmDialog
            title='删除分组'
            subText='此操作不可逆转，确认删除？'
            hideDialog={hideDeleteGroupDialog}
            toggleHideDialog={hideDeleteGroupDialogCallback.setTrue}
            toggleConfirm={() => selectedGroup && toggleDeleteGroup(selectedGroup)}
            errorMessage={deleteGroupDialogErrorMessage}
        />
        <ConfirmDialog
            title='删除数据'
            subText='此操作不可逆转，确认删除？'
            hideDialog={hideDeleteDataDialog}
            toggleHideDialog={hideDeleteDataDialogCallback.setTrue}
            toggleConfirm={() => selectedData && toggleDeleteData(selectedData)}
            errorMessage={deleteDataDialogErrorMessage}
        />
        <Upload
            hideDialog={hideUploadDialog}
            toggleHideDialog={hideUploadDialogCallback.setTrue}
            selectedGroup={selectedGroup}
            onSelectGroup={setSelectedGroup}
            onUploaded={() => {
                hideUploadDialogCallback.setTrue()
                loadDataList()
            }}
            groups={groups}
        />
    </div>
}

export default Sidebar