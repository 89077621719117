import { Grid } from '@mui/material';
import ItemCard from './ItemCard';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { useAppSelector } from 'src/service/store';

const DataSetSelection: React.FC = () => {
    const { groups } = useAppSelector(e => e.dataset);
    const params = useParams();

    const categoryGroups = useMemo(() => {
        const start = params.series + '/' + params.category + '/';
        return groups.filter(e => e.name.startsWith(start)).map(e => ({ ...e, name: e.name.slice(start.length) }))
    }, [groups, params.category, params.series])

    return <div>
        <Grid container spacing={4}>
            {categoryGroups.map(e => (
                <Grid key={e.name} item>
                    <ItemCard {...e} />
                </Grid>
            ))}
        </Grid>
    </div>
};

export default DataSetSelection