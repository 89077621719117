import { Alert, Box, Divider, Grid, Slider, Stack, Typography } from "@mui/material";
import { IGeneData } from "src/component/GeneSelection";
import Sidebar2 from "src/container/Sidebars/Sidebar2";
import ReactECharts from 'echarts-for-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useLocalStorage } from "src/service/hooks";
import styles from './index.module.scss'
import { partialPathConverter } from "src/utility/common";
import { useAppSelector } from "src/service/store";
import { downloadTxt, optionToSvg } from "src/service/echarts";
import { timeOrder } from "src/component/DataPointSelectrion";

const UMAPPlot2: React.FC = () => {
    const [errorMsg, setErrorMsg] = useState<string>();
    const [geneData, setGeneData] = useState<IGeneData[]>([]);
    const [symbolSize, setSymbolSize] = useLocalStorage('UMAPPlotSymbolSize', 4);
    const [columns, setColumns] = useLocalStorage('UMAPColumns', 1);
    const [aspect, setAspect] = useLocalStorage('UMAPAspect', 1.5);
    const groups = useMemo(() => geneData.map(e => e.group), [geneData])
    const names = useMemo(() => geneData.map(e => e.name), [geneData])
    const optionRef = useRef<any>()

    useEffect(() => {
        if (geneData.length > 0) {
            setErrorMsg(undefined)
        }
    }, [geneData])

    useEffect(() => {
        if (errorMsg) {
            setGeneData([])
        }
    }, [errorMsg])

    const theme = useAppSelector(e => e.config.theme);

    const exportSvg = useCallback(async (i: number) => {
        downloadTxt(optionRef.current[i].title.text + '.svg', optionToSvg(optionRef.current[i], theme, 1200, 800));
    }, [theme])

    const option = useMemo(() => geneData.map((e, i) => {
        let minX = Math.min(...e.x)
        let maxX = Math.max(...e.x)
        let minY = Math.min(...e.y)
        let maxY = Math.max(...e.y)
        const paddingX = (maxX - minX) * 0.1
        const paddingY = (maxY - minY) * 0.1
        minX -= paddingX;
        maxX += paddingX;
        minY -= paddingX;
        maxY += paddingY;
        if ((maxX - minX) / (maxY - minY) > aspect) {
            const extendY = ((maxX - minX) / aspect - (maxY - minY)) / 2;
            minY -= extendY;
            maxY += extendY;
        } else {
            const extendX = ((maxY - minY) * aspect - (maxX - minX)) / 2;
            minX -= extendX;
            maxX += extendX;
        }
        return {
            toolbox: {
                top: 16,
                right: 48,
                feature: {
                    dataZoom: {
                        title: {
                            zoom: 'Zoom',
                            back: 'Back'
                        }
                    },
                    restore: {
                        title: 'Restore'
                    },
                    saveAsImage: {
                        show: false
                    },
                    mySaveAsImage: {
                        title: 'Save As Image',
                        icon: 'path://M4.7,22.9L29.3,45.5L54.7,23.4M4.6,43.6L4.6,58L53.8,58L53.8,43.6M29.2,45.1L29.2,0',
                        onclick: () => exportSvg(i)
                    }
                }
            },
            tooltip: {
                show: true,
                formatter: (p: any) => {
                    return String(p.data[2])
                },
                axisPointer: {
                    type: 'cross'
                }
            },
            axisPointer: {
                label: {
                    backgroundColor: '#666',
                }
            },
            title: {
                text: partialPathConverter([groups, names], [e.group, e.name, e.origIdent]),
                left: 'center',
                top: 20,
                textStyle: {
                    fontSize: Math.max(18 / columns, 12)
                }
            },
            xAxis: {
                scale: true,
                min: minX,
                max: maxX,
                axisLabel: {
                    showMaxLabel: false,
                    showMinLabel: false,
                }
            },
            yAxis: {
                scale: true,
                min: minY,
                max: maxY,
                axisLabel: {
                    showMaxLabel: false,
                    showMinLabel: false,
                }
            },
            visualMap: {
                min: 0,
                max: 1,
                text: ['High', 'Low'],
                color: ["#67000D", "#970B13", "#BA1419", "#D62321", "#EE3F31", "#EE6255", "#ED847A", "#ECA6A0", "#EBC8C5", "#EBEBEB"],
                dimension: 2,
                precision: 3,
                top: 'center',
                right: 10,
                textStyle: {
                    color: theme == 'dark' ? '#FFF' : '#000'
                }
            },
            series: [
                {
                    symbolSize: symbolSize / columns,
                    data: e.x.map((x, i) => [x, e.y[i], e.geneData[i]]).sort((a, b) => a[2] - b[2]),
                    type: 'scatter',
                }
            ],
            grid: {
                top: 60,
                right: 50,
                bottom: 40,
                left: 60
            }
        }
    }), [aspect, columns, exportSvg, geneData, groups, names, symbolSize, theme]);

    useEffect(() => {
        optionRef.current = option
    }, [option])

    return <div className={styles.container}>
        <div>
            <Sidebar2 dataTypes={[0, 1]} onError={setErrorMsg} onQuery={d => setGeneData(d
                .sort((a, b) => a.group.localeCompare(b.group))
                .sort((a, b) => a.name.localeCompare(b.name))
                .sort((a, b) => (timeOrder[a.origIdent ?? ''] ?? -1) - (timeOrder[b.origIdent ?? ''] ?? -1)))}>
                <Stack>
                    <Box style={{ margin: '8px 16px 8px 16px' }}>
                        <Typography variant="caption" gutterBottom>
                            Number of Columns
                        </Typography>
                        <Slider min={1} max={3} value={columns} onChange={(e, v) => setColumns(v as number)} />
                    </Box>
                    <Box style={{ margin: '8px 16px 8px 16px' }}>
                        <Typography variant="caption" gutterBottom>
                            Mark Size
                        </Typography>
                        <Slider min={1} max={16} value={symbolSize} onChange={(e, v) => setSymbolSize(v as number)} />
                    </Box>
                    <Box style={{ margin: '8px 16px 8px 16px' }}>
                        <Typography variant="caption" gutterBottom>
                            Aspect ratio
                        </Typography>
                        <Slider min={0.5} max={2} step={0.1} value={aspect} onChange={(e, v) => setAspect(v as number)} />
                    </Box>
                </Stack>
            </Sidebar2>
        </div>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            {errorMsg && <div style={{ margin: 16 }}><Alert severity="info">{errorMsg}</Alert></div>}
            {geneData.length > 0 && <>
                <Scrollbars style={{ height: undefined, flex: 1 }} autoHide>
                    <Grid container minWidth={columns * 340}>
                        {option.map((e, i) => <Grid key={i} item xs={12 / columns}>
                            <ReactECharts option={e} style={{ height: `calc(calc(100vh - 250px) / ${columns})`, minHeight: 700 / columns }} notMerge={true} theme={theme} />
                        </Grid>)}
                    </Grid>
                </Scrollbars>
            </>}
        </div>
    </div>
}

export default UMAPPlot2;