import { Alert, Box, Grid, Slider, Switch, Typography } from "@mui/material";
import { IGeneData } from "src/component/GeneSelection";
import Sidebar2 from "src/container/Sidebars/Sidebar2";
import React, { useEffect, useMemo, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useLocalStorage } from "src/service/hooks";
import styles from './index.module.scss';
import Plotly from 'plotly.js-dist'
import plotlyTemplate from "./plotlyTemplate";
import { partialPathConverter } from "src/utility/common";
import { useAppSelector } from "src/service/store";
import { timeOrder } from "src/component/DataPointSelectrion";

const order = ["tgs1+_Neoblast", "Nb2", "Gut", "Neural", "Epidermal", "Muscle", "Pharynx", "Protonephridia", "cathe+_cells", "cathepsin+ cells", "Parenchymal"]

const colorsMap: Record<string, string> = {
    'tgs1+-Neoblast': "#1f78b4",
    'Nb2': "#1f78b4", 'Gut': "#34942e",
    'Neural': "#e31a1c", 'Epidermal': "#fa9614",
    'Muscle': "#8832b3", 'Pharynx': "#e9ed00",
    'Protonephridia': "#a64d1c",
    'cathe+-cells': "#696969",
    'cathepsin+ cells': "#696969",
    'Parenchymal': "#00FFFF"
}

const colorsMap2: Record<string, string> = {
    'ctr': "#F8766D",
    'control(RNAi)': "#F8766D",
    'nsf(RNAi)': "#00BFC4",
    "scg5(RNAi)": '#00BFC4',
    "VFA": '#00BFC4',
    "VFA_RNAi": '#00BFC4',
    "0h": "#9c1b45",
    "12h": "#f26d44",
    "1.5d": "#acd7a3",
    "3d": "#3287bc",
    "5d": "#bb80b6",
    "10d": "#412977"
}

const ViolinPlot2: React.FC = () => {
    const [errorMsg, setErrorMsg] = useState<string>();
    const [geneData, setGeneData] = useState<IGeneData[]>([]);
    const [columns, setColumns] = useLocalStorage('ClusterLegendColumns', 1);
    const [mergeCellType, setMergeCellType] = useLocalStorage('ViolinPlotMergeCellType', false);
    const [boxVisible, setBoxVisible] = useLocalStorage('ViolinBoxVisible', true);
    const groups = useMemo(() => geneData.map(e => e.group), [geneData])
    const names = useMemo(() => geneData.map(e => e.name), [geneData])

    useEffect(() => {
        if (geneData.length > 0) {
            setErrorMsg(undefined)
        }
    }, [geneData])

    useEffect(() => {
        if (errorMsg) {
            setGeneData([])
        }
    }, [errorMsg])

    const theme = useAppSelector(e => e.config.theme);

    const isSingleLabel = useMemo(() => geneData.every(x => x.cellType.every(y => y == geneData[0]?.cellType[0])), [geneData])

    const data = useMemo(() => {
        return geneData.map((e) => {
            if (!e) return {}
            const orderIndex = e.cellType.map((t, i) => ({ t, i })).sort((a, b) => order.indexOf(a.t) - order.indexOf(b.t)).map(e => e.i)
            const x = mergeCellType ? undefined : orderIndex.map(i => e.cellType[i])
            const name = partialPathConverter([groups, names], [e.group, e.name, e.origIdent]);
            return {
                type: 'violin',
                x,
                y: orderIndex.map(i => e.geneData[i]),
                legendgroup: x,
                scalegroup: x,
                offsetgroup: name,
                name,
                box: {
                    visible: boxVisible
                },
                meanline: {
                    visible: false
                },
                fillcolor: isSingleLabel || mergeCellType ? colorsMap2[e.origIdent ?? ''] : undefined,
                line: {
                    color: theme == 'dark' ? '#ddd' : '#666',
                },
                width: isSingleLabel ? undefined : 0.8,
                transforms: [{
                    type: 'groupby',
                    groups: x,
                    styles: Object.keys(colorsMap).map(key => ({ target: key, value: { line: { color: colorsMap[key] } } }))
                }]
            }
        })
    }, [boxVisible, geneData, groups, isSingleLabel, mergeCellType, names, theme]);

    const layout = useMemo(() => {
        return geneData.map((e, i) => {
            const name = partialPathConverter([groups, names], [geneData[i].group, geneData[i].name, geneData[i].origIdent]);
            return {
                title: mergeCellType ? undefined : name,
                template: theme == 'dark' ? plotlyTemplate : undefined,
                violinmode: mergeCellType ? 'overlay' : 'group',
                yaxis: {
                    zeroline: true,
                    rangemode: 'nonnegative'
                },
            }
        })
    }, [geneData, groups, mergeCellType, names, theme]);

    useEffect(() => {
        const opts = {
            displaylogo: false,
            toImageButtonOptions: {
                format: 'svg',
                filename: 'violin plot',
                height: 800,
                width: 1200,
                scale: 1
            }
        }
        if (geneData.length > 0) {
            if (!(mergeCellType || isSingleLabel)) {
                geneData.forEach((e, i) => Plotly.newPlot(`d3plot_${i}`, [data[i]], layout[i], opts))
            } else {
                Plotly.newPlot(`d3plot`, data, layout[0], opts);
            }
        }
    }, [data, geneData, geneData.length, layout, mergeCellType, columns, isSingleLabel])

    return <div className={styles.container}>
        <div>
            <Sidebar2 dataTypes={[0]} onError={setErrorMsg} onQuery={d => setGeneData(d
                .sort((a, b) => a.group.localeCompare(b.group))
                .sort((a, b) => a.name.localeCompare(b.name))
                .sort((a, b) => (timeOrder[a.origIdent ?? ''] ?? -1) - (timeOrder[b.origIdent ?? ''] ?? -1)))} >
                <>
                    <Box style={{ margin: '8px 16px 8px 16px' }}>
                        <Typography variant="caption" gutterBottom>
                            Number of Columns
                        </Typography>
                        <Slider min={1} max={2} value={columns} onChange={(e, v) => setColumns(v as number)} />
                    </Box>
                    <Box style={{ margin: '8px 16px 8px 16px' }}>
                        <Typography variant="caption" gutterBottom>
                            Box Visible
                        </Typography>
                        <Switch checked={boxVisible} onChange={(e) => setBoxVisible(e.target.checked)} />
                    </Box>
                    <Box style={{ margin: '8px 16px 8px 16px' }}>
                        <Typography variant="caption" gutterBottom>
                            Merge Cell Type
                        </Typography>
                        <Switch checked={mergeCellType} onChange={(e) => setMergeCellType(e.target.checked)} />
                    </Box>
                </>
            </Sidebar2>
        </div>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            {errorMsg && <div style={{ margin: 16 }}><Alert severity="info">{errorMsg}</Alert></div>}
            {geneData.length > 0 && <>
                <Scrollbars style={{ height: undefined, flex: 1 }} autoHide>
                    {!(mergeCellType || isSingleLabel) && <Grid container minWidth={columns * 380}>
                        {geneData.map((x, i) =>
                            <Grid key={i} item xs={12 / columns}>
                                <div id={`d3plot_${i}`} style={{ height: '100%', width: '100%' }} />
                            </Grid>
                        )}
                    </Grid>}
                    {(mergeCellType || isSingleLabel) &&
                        <div style={{ height: 700, position: 'relative' }}>
                            <div id={`d3plot`} style={{ height: '100%', position: 'absolute', top: 0, left: 0, width: '100%' }} />
                        </div>
                    }
                </Scrollbars>
            </>}
        </div>
    </div>
}

export default ViolinPlot2;