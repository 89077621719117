import { Card, Stack } from '@mui/material';
import { IView } from 'src/model/dataset';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './index.module.scss';

const ItemCard: React.FC<IView> = (props) => {
    const navigate = useNavigate();
    const params = useParams();

    const navigateToItem = async () => {
        navigate(
            '/legacy' +
            '/' + encodeURIComponent(params.series ?? '') +
            '/' + encodeURIComponent(params.category ?? '') +
            '/' + encodeURIComponent(params.dataset ?? '') +
            '/' + encodeURIComponent(props.name))
    }

    return <Card className={styles.card} onClick={navigateToItem}>
        <Stack>
            <div className={styles.CardImg} style={{ height: 240, background: `url(${props.img})` }} />
            <div style={{ margin: 16, textAlign: 'center' }}>
                <div style={{ fontSize: 24 }}>{props.name}</div>
            </div>
        </Stack>
    </Card>
}

export default ItemCard;