import { IconButton, Stack } from "@fluentui/react";
import React from "react";

interface IItemCommandBar {
    toggleDelete: () => void
    toggleMoveUp: () => void
    toggleMoveDown: () => void
}

const ItemCommandBar: React.FC<IItemCommandBar> = (props) => {
    return <Stack verticalAlign='center' className={'itemCommandBar'} horizontal>
        <IconButton
            iconProps={{ iconName: 'Up' }}
            title="上移"
            onClick={e => {
                e.stopPropagation();
                props.toggleMoveUp();
            }}
            onMouseDown={e => {
                e.stopPropagation();
            }}
        />
        <IconButton
            iconProps={{ iconName: 'Down' }}
            title="下移"
            onClick={e => {
                e.stopPropagation();
                props.toggleMoveDown();
            }}
            onMouseDown={e => {
                e.stopPropagation();
            }}
        />
        <IconButton
            iconProps={{ iconName: 'Delete' }}
            title="删除"
            onClick={e => {
                e.stopPropagation();
                props.toggleDelete();
            }}
            onMouseDown={e => {
                e.stopPropagation();
            }}
        />
    </Stack>
}

export default ItemCommandBar