import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './service/store';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { initializeIcons } from '@fluentui/font-icons-mdl2'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import darkTheme from 'src/component/Echarts/darkTheme';
import * as echarts from 'echarts';
import AppThemeProvider from './container/AppThemeProvider';
import ReactGA from 'react-ga4';

initializeIcons('./font/');

echarts.registerTheme('dark', darkTheme);

ReactGA.initialize('G-FBGJ659BZ0')

ReactDOM.render(
  <Provider store={store}>
    <AppThemeProvider>
      <HashRouter>
        <App />
      </HashRouter>
    </AppThemeProvider>
  </Provider>,
  document.getElementById('root')
);