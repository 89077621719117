import { Card, Stack } from '@mui/material';
import { ICategory } from 'src/model/dataset';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';

const ItemCard: React.FC<ICategory> = (props) => {
    const navigate = useNavigate();
    const [cardStyle, setCardStyle] = useState<React.CSSProperties>();
    const [imgOpacity, setImgOpacity] = useState(1)

    const navigateToItem = async () => {
        navigate(props.name);
    }

    return <Card className={styles.card} style={cardStyle} onClick={navigateToItem}>
        <Stack>
            <div className={styles.CardImg} style={{ height: 300, background: `url(${props.img})`, opacity: imgOpacity }} />
            <div style={{ margin: 16, textAlign: 'center' }}>
                <div style={{ fontSize: 24 }}>{props.title}</div>
                <div style={{ color: '#333333', fontSize: 14, marginTop: 4 }}>{props.description}</div>
            </div>
        </Stack>
    </Card>
}

export default ItemCard;