import React, { useCallback } from "react";
import { useParams } from "react-router";
import { Scrollbars } from 'react-custom-scrollbars';
import styles from './index.module.scss'
import { useAppSelector } from "src/service/store";
import { Autocomplete, Checkbox, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { IApiItem, queryData, queryRowData } from "src/service/celldata";
import { useEffect } from "react";
import { useRef } from "react";

interface IRowGeneData {
    group: string
    name: string
    genes: string[]
    period: string[]
    geneData: number[][]
}

interface ISidebarHeatMap {
    children?: JSX.Element,
    dataTypes: number[],
    onQuery?: (data: IRowGeneData[], genes?: string[]) => void
    onError?: (msg: string) => void
}

const SidebarHeatMap: React.FC<ISidebarHeatMap> = (props) => {
    const params = useParams();
    const allItems = useAppSelector(e => e.dataset.items);
    const groups = useAppSelector(e => e.dataset.groups);
    const group = groups.filter(e => e.name == params.series + '/' + params.category + '/' + params.dataset)[0];
    const items = group ? allItems.slice(group.startIndex, group.startIndex + group.count).filter(e => props.dataTypes.indexOf(e.type) > -1) : [];
    const [selectItems, setSelectItems] = useState<IApiItem[]>([]);
    const [genes, setGenes] = useState(['SMED30007406']);
    const timerRef = useRef<NodeJS.Timeout>();
    const propsRef = useRef(props);
    const [allGeneIds, setAllGeneIds] = useState<string[]>([]);
    const toggleQueryDataRef = useRef<() => void>(() => { /** */ });

    const toggleSelect = (item: IApiItem) => {
        if (selectItems.indexOf(item) > -1) {
            setSelectItems(selectItems.filter(e => e != item))
        } else {
            setSelectItems(allItems.filter(e => selectItems.indexOf(e) > -1 || e == item))
        }
    }

    useEffect(() => {
        propsRef.current = props
    }, [props])

    const toggleQueryData = useCallback(async () => {
        queryData(selectItems, 'cellname').then(res => {
            if (res.code == 0)
                setAllGeneIds(Array.from(new Set(res.data.reduce<string[]>((pre, cur) => [...pre, ...cur.geneData.split('\n')], []).filter(e => e.startsWith('SMED')))))
        });
        const queryGenes = genes.filter(e => e.trim() != '');
        if (queryGenes.length == 0) {
            propsRef.current.onError?.("Please Input Gene ID");
            return;
        }
        if (items.length == 0) {
            propsRef.current.onError?.("No Data");
            return;
        }
        if (selectItems.length == 0) {
            propsRef.current.onError?.("Please Select Data");
            return;
        }
        const res = await queryRowData(selectItems, queryGenes)
        if (res.code == 0) {
            try {
                propsRef.current.onQuery?.(res.data.map((e, i) => ({
                    group: selectItems[i].group,
                    name: selectItems[i].name,
                    genes: queryGenes,
                    period: e.name.split('\n').slice(0, -1),
                    geneData: e.geneData.map(e => e.split('\n').slice(0, -1).map(e => parseFloat(e))),
                })), genes)
            } catch (e) {
                propsRef.current.onError?.("数据出现错误或损坏")
            }
        } else {
            propsRef.current.onError?.(res.message)
        }
    }, [genes, items.length, selectItems]);

    useEffect(() => {
        toggleQueryDataRef.current = toggleQueryData
    }, [toggleQueryData])

    useEffect(() => {
        timerRef.current = setTimeout(() => toggleQueryDataRef.current(), 200)
        return () => timerRef.current && clearTimeout(timerRef.current)
    }, [selectItems])

    useEffect(() => {
        timerRef.current = setTimeout(() => toggleQueryDataRef.current(), 1000)
        return () => timerRef.current && clearTimeout(timerRef.current)
    }, [genes])

    useEffect(() => {
        if (genes[genes.length - 1] != '')
            setGenes([...genes, ''])
        if (genes.some((e, i) => e == '' && i != genes.length - 1)) {
            setGenes([...genes.filter(e => e != ''), ''])
        }
    }, [genes])

    return (
        <div className={styles.container}>
            <div style={{ flex: 1 }}>
                <Scrollbars autoHide>
                    <List style={{ width: 'calc(100% - 16px)', margin: 8 }} subheader={<ListSubheader>Genes</ListSubheader>}>
                        {genes.map((value, i) => {
                            const autocompleteOptions = [...allGeneIds.filter(x => x.indexOf(value) > -1), ...allGeneIds.filter(x => x.indexOf(value) == -1)]
                            if (autocompleteOptions.indexOf(value) == -1) autocompleteOptions.push(value)
                            const handleInput = (v: string) => setGenes(genes.map((t, j) => i == j ? v : t))
                            return (
                                <ListItem key={i}>
                                    <Autocomplete
                                        style={{ width: '100%' }}
                                        disablePortal
                                        options={autocompleteOptions.slice(0, 100)}
                                        value={value}
                                        onChange={(e, v) => handleInput(v ?? '')}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            onChange={e => handleInput(e.target.value)}
                                            value={value}
                                            variant="standard"
                                            placeholder="gene" />}
                                    />

                                </ListItem>
                            )
                        })}
                    </List>
                    <List style={{ width: 'calc(100% - 16px)', margin: 8 }} subheader={<ListSubheader>Data</ListSubheader>}>
                        {items.map((item) => (
                            <ListItem
                                key={item.id}
                                disablePadding>
                                <ListItemButton onClick={() => toggleSelect(item)} dense>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={selectItems.indexOf(item) > -1}
                                            onClick={() => toggleSelect(item)}
                                            disableRipple
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={item.name} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Scrollbars >
            </div>
            {props.children && <>
                <Divider />
                <Stack direction={'column'} style={{ width: 'calc(100% - 16px)', margin: 8 }} >
                    {props.children}
                </Stack>
            </>}
        </div>
    )
}

export type { IRowGeneData }

export default SidebarHeatMap;