import { Grid } from '@mui/material';
import ItemCard from './ItemCard';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { useAppSelector } from 'src/service/store';

const ViewSelection: React.FC = () => {
    const params = useParams();
    const { views } = useAppSelector(e => e.dataset);
    const allItems = useAppSelector(e => e.dataset.items);
    const groups = useAppSelector(e => e.dataset.groups);
    const group = groups.filter(e => e.name == params.series + '/' + params.category + '/' + params.dataset)[0];
    const items = group ? allItems.slice(group.startIndex, group.startIndex + group.count) : []
    const dataTypeSet = new Set(items.map(e => e.type))
    const supportViews = views.filter(e => e.type.some(t => dataTypeSet.has(t)))
    return <div>
        <Grid container spacing={4}>
            {supportViews.map(e => (
                <Grid key={e.name} item>
                    <ItemCard {...e} />
                </Grid>
            ))}
        </Grid>
    </div>
};

export default ViewSelection