import Worker from "worker-loader!./worker.ts"

type Callback = (blob: Blob) => void;
const listener = new Map<string, Callback>();
const workers: Worker[] = [];
const count = [0];

for (let i = 0; i < 8; i++) {
    const worker = new Worker();
    worker.onmessage = ({ data }) => {
        listener.get(data.id)?.(data.ret);
        listener.delete(data.id);
    }
    workers.push(worker)
}

const gzip = (blob: Blob): Promise<Blob> => (
    new Promise((resolve) => {
        const id = String(Math.random());
        listener.set(id, data => resolve(data));
        workers[count[0]++ % workers.length].postMessage({ id, blob });
    })
)

export { gzip };