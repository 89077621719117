const handleValueChange = (f: React.Dispatch<string>) =>
    (e: React.ChangeEvent<HTMLTextAreaElement>) => f(e.target.value);

const partialPathConverter = (paths: string[][], path: (string | undefined)[]) => {
    let flag = false;
    const result = []
    for (let i = 0; i < path.length; i++) {
        if (!flag && paths[i]?.some(x => paths[i].some(y => x != y)))
            flag = true;
        if ((flag || !paths[i]) && path[i])
            result.push(path[i])
    }
    return result.join('/')
}

export { handleValueChange, partialPathConverter }