import MainContent from 'src/component/MainContent';
import Navigation from 'src/component/Navigation';
import React from 'react';
import styles from './index.module.scss'

const MainFrame: React.FC = () => {
    return <div className={styles.container}>
        <Navigation />
        <MainContent />
    </div>
}

export default MainFrame;