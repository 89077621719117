import { Grid, Paper, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import CategoryManager from "./CategoryManager";
import DataManager from "./DataManager";
import FileManager from "./FileManager";
import OverView from "./OverView";
import { leftToggleButtonGroupStyle, rightContentPaperStyle } from "./styles";
import ViewManager from "./ViewManager";

const ManagerPage: React.FC = () => {
    const [view, setView] = useState('overview');
    const navigate = useNavigate();
    const params = useParams();

    const pages: IPageList = [
        { title: '概览', path: 'overview', element: <OverView /> },
        { title: '类别管理', path: 'category', element: <CategoryManager /> },
        { title: '数据管理', path: 'data', element: <DataManager /> },
        { title: '视图管理', path: 'view', element: <ViewManager /> },
        { title: '文件管理', path: 'file', element: <FileManager /> },
    ]

    useEffect(() => {
        navigate(`/manager/${view}`)
    }, [navigate, view])

    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <Stack>
                    <ToggleButtonGroup
                        orientation="vertical"
                        value={view}
                        exclusive
                        onChange={(e, v) => setView(v)}
                        sx={leftToggleButtonGroupStyle}
                    >
                        {pages.map(p => (
                            <ToggleButton key={p.path} value={p.path}>
                                {p.title}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Stack>
            </Grid>
            <Grid item xs={9}>
                <Paper sx={rightContentPaperStyle}>
                    {pages.find(e => e.path == params.page)?.element}
                </Paper>
            </Grid>
        </Grid>
    )
}

export default ManagerPage