import { SxProps, Theme } from "@mui/material"

const leftToggleButtonGroupStyle: SxProps<Theme> = {
    gap: 1,
    '&>button': {
        border: 'none',
        borderRadius: '4px !important'
    }
}

const rightContentPaperStyle: SxProps<Theme> = {
    minHeight: 'calc(100vh - 200px)',
    background: '#FFFFFF09',
    borderRadius: '4px !important'
}

export { leftToggleButtonGroupStyle, rightContentPaperStyle }