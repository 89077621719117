import DataSetSelection from 'src/component/DataSetSelection';
import ViewSelection from 'src/component/ViewSelection';
import ClusterLegend2 from 'src/container/ClusterLegend2';
import HeatMap2 from 'src/container/HeatMap2';
import UMAPPlot2 from 'src/container/UMAPPlot2';
import ViolinPlot2 from 'src/container/ViolinPlot2';
import React from 'react';
import { useParams } from 'react-router-dom';
import styles from './index.module.scss'
import CategorySelection from '../CategorySelection';

const MainFrame: React.FC = () => {
    const params = useParams();

    let content = <></>;

    if (!params.category) {
        content = <div className={styles.selectionContainer}><CategorySelection /></div>
    } else if (!params.dataset) {
        content = <div className={styles.selectionContainer}><DataSetSelection /></div>
    } else if (!params.view) {
        content = <div className={styles.selectionContainer}><ViewSelection /></div>
    } else if (params.view == 'Violin plot') {
        content = <ViolinPlot2 />
    } else if (params.view == 'Feature plot') {
        content = <UMAPPlot2 />
    } else if (params.view == 'Cluster plot') {
        content = <ClusterLegend2 />
    } else if (params.view == 'Heatmap') {
        content = <HeatMap2 />
    }

    return content
}

export default MainFrame;