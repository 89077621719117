import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from '../../service/store';
import { useNavigate } from "react-router-dom";
import { Button, SxProps, Theme } from '@mui/material';
import { AppBarButton } from '../Button';
import { loadUserInfo, logoutUser } from 'src/service/user';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import { setTheme } from 'src/model/config';

interface INavigationView {
    pages: IPageList;
    children: JSX.Element;
}

const ToolbarStyle: SxProps<Theme> = {
    display: 'flex'
}

const NavigationView: React.FC<INavigationView> = (props) => {
    const [anchorElUser, setAnchorElUser] = React.useState<Element>();
    const user = useAppSelector(e => e.user);
    const config = useAppSelector(e => e.config);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = (f?: () => void) => {
        setAnchorElUser(undefined);
        f?.();
    };

    const handleSwitchTheme = () => {
        dispatch(setTheme(config.theme == 'light' ? 'dark' : 'light'))
    }

    useEffect(() => {
        loadUserInfo();
    }, [])

    const ToolbarItemStyle: SxProps<Theme> = useMemo(() => ({
        mx: 1,
        color: config.theme == 'dark' ? 'white' : 'black',
        display: 'flex',
        alignItems: 'center'
    }), [config.theme]);

    const AppBarStyle: SxProps<Theme> = useMemo(() => ({
        display: 'fixed',
        backgroundColor: config.theme == 'dark' ? '#20202000' : 'rgba(255,255,255,0.72)',
        boxShadow: config.theme == 'dark' ? 'none' : 'inset 0px -1px 1px #e7ebf0;',
        borderBottom: config.theme == 'dark' ? '#FFFFFF12' : '#0000',
        backdropFilter: 'blur(20px)'
    }), [config.theme])

    return (
        <>
            <AppBar sx={AppBarStyle}>
                <Container maxWidth="xl">
                    <Toolbar sx={ToolbarStyle}>
                        <Box sx={{ flexGrow: 1, display: 'flex' }}>
                            <Typography variant="h6" sx={{ ...ToolbarItemStyle, mr: 4 }}>
                                RegeOMICS DataBase
                            </Typography>
                            {props.pages.map(e => (
                                <AppBarButton key={e.path} sx={ToolbarItemStyle} onClick={() => navigate(e.path)}>
                                    {e.title}
                                </AppBarButton>
                            ))}
                        </Box>
                        {/* {user.isLogin == true && <Box sx={{ flexGrow: 0 }}>
                            <AppBarButton onClick={handleOpenUserMenu} sx={ToolbarItemStyle}>
                                {user.username}<ExpandMoreIcon />
                            </AppBarButton>
                            <Menu
                                sx={{ mt: '45px' }}
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={() => handleCloseUserMenu()}
                            >
                                {user.role == "admin" && <MenuItem onClick={() => handleCloseUserMenu(() => navigate('/manager/overview'))}>
                                    <Typography textAlign="center">Dashboard</Typography>
                                </MenuItem>}
                                <MenuItem onClick={() => handleCloseUserMenu(logoutUser)}>
                                    <Typography textAlign="center">Logout</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>}
                        {user.isLogin == false && <Box sx={{ flexGrow: 0, display: 'flex' }}>
                            <AppBarButton sx={ToolbarItemStyle} onClick={() => navigate('/login')}>
                                Login
                            </AppBarButton>
                        </Box>} */}
                        <AppBarButton onClick={handleSwitchTheme}>
                            {config.theme == 'dark' && <DarkModeOutlinedIcon />}
                            {config.theme == 'light' && <LightModeOutlinedIcon />}
                        </AppBarButton>
                    </Toolbar>
                </Container>
            </AppBar >
            <Container maxWidth="xl" sx={{ mt: 10, mb: 2 }}>
                {props.children}
            </Container>
        </>
    )
}

export default NavigationView;