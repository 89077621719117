import { Container, Paper, SxProps, Theme } from "@mui/material";
import React, { useEffect } from "react";

const PaperStyle: SxProps<Theme> = {
    margin: '0 auto',
    padding: 0,
    width: 1016
}

const ThreeDPage: React.FC = () => {
    // 他们直接给了我一个html，就只能这样了
    return <Container >
        <Paper elevation={3} sx={PaperStyle}>
            <iframe src='3d/NB2_3D_0.1.html' style={{ width: 1016, height: 1016, borderWidth: 0 }} />
        </Paper>
    </Container>
}

export default ThreeDPage;